import { useRef, useState, useCallback, useEffect} from "react";
import OverlayMenuAnalysisMenu from "../components/OverlayMenuAnalysisMenu";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./BenchmarkingEfficiency.module.css";
import Select from 'react-select';
import Plot from 'react-plotly.js';
import { Oval } from 'react-loader-spinner';
import config from "./config";
import moment from 'moment';





const useFetch = (url) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchUrl = async () => {
    try {
      const response = await fetch(url);
      const rawData = await response.json();
      
      const formattedData = rawData.map(item  => {
        // Split the string at the hyphen and capitalize the first letter of the second part
        const parts = item.split('-');
        if (parts.length > 1) {
          parts[1] = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
        }
        
        const formattedItem = parts.join(' ');
        
        return { value: item, label: formattedItem };
      });
  
      // Sorting the data alphabetically
      const sortedData = formattedData.sort((a, b) => a.label.localeCompare(b.label));
  
      setData(sortedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (url) {
      fetchUrl();
    }
  }, [url]);

  return [data, loading];
};

//Functions to fetch and plot charts

const fetchChartData = async (url, clients, excludedClient, projects, c_projects, start, end, industry_avg, sector_avg, industry, sector) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ clients, excluded_client: excludedClient, projects, c_projects, start_date: start, end_date: end , industry_avg, sector_avg, industry, sector}),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};



const clientColorMap = {
  "Customer": '#004225',
  "Task & Process": '#a7c5f9',
  "NVA": '#800517',
  "Efficiency Index": '#878787',
  "Pace Rating": '#02557a',
  // Add other chart types and colors here
};


const plotChart = (data, title, color, selectedClient, selectedOption, xKey = '%') => {
  // Map the client data
  const clientData = data.map((record) => {
    const xValue = record[xKey];
    const isEmpty = xValue === 0.0;

    
  
    return {
      x: [xValue],
      y: [record.client_name],
      type: 'bar',
      orientation: 'h',
      width: 0.75,
      text: [xValue / 100],
      texttemplate: '%{text:.0%}',
      name: record.client_name,
      marker: { color: [record.client_name === selectedClient ? clientColorMap[selectedOption] : color] },
      hoverinfo: isEmpty ? 'none' : 'all',  // Disable hover info for empty bars
      showlegend: !isEmpty,  // Hide legend for empty bars
      editable: true,
    };
  });
  const clientAnnotations = clientData
  .filter((data) => data.x[0] === 0.0)
  .map((data, index) => ({
    x: 10,
    y: data.y[0],
    text: '0.0%',
    showarrow: false,
    font: {
      color: 'black',
      size: 14,
    },
  }));

  // Add the industry average data as a separate trace
  const industryAvgData = {
    x: [data[0]['industry_avg']],
    y: [[' Industry Average']],
    type: 'bar',
    orientation: 'h',
    width:0.75,
    text: [data[0]['industry_avg'] / 100],
    texttemplate: '%{text:.0%}',
    name: 'Industry Average',
    marker: { color: '#F7A12C' }, // Color for industry average
  };
  const sectorAvgData = {
    x: [data[0]['sector_avg']], // Make sure the server sends this key
    y: [[' Sector Average']],
    type: 'bar',
    orientation: 'h',
    width:0.75,
    text: [data[0]['sector_avg'] / 100],
    texttemplate: '%{text:.0%}',
    name: 'Sector Average',
    marker: { color: '#F7A12C' }, // Choose a color for the sector average
  };

  const industryAvgAnnotation = industryAvgData.x[0] === 0.0 ? [{
    x: 10,
    y: ' Industry Average',
    text: '0.0%',
    showarrow: false,
    font: {
      color: 'black',
      size: 14,
    },
  }] : [];

  // Generate annotation for sectorAvgData if value is 0.0%
  const sectorAvgAnnotation = sectorAvgData.x[0] === 0.0 ? [{
    x: 10,
    y: ' Sector Average',
    text: '0.0%',
    showarrow: false,
    font: {
      color: 'black',
      size: 14,
    },
  }] : [];

  // Combine all annotations
  const annotations = [...clientAnnotations, ...industryAvgAnnotation, ...sectorAvgAnnotation];

  return (
    <Plot
      data={[...clientData, industryAvgData,sectorAvgData]}
      layout={{
        //backgroundcolor: "rgb(255,0,0)",
        width:550,
        height: 'auto',
        xaxis: { title },
        template: 'plotly_white',
        barmode: 'group',
        showlegend: false,
        margin: {
          l: 250,
          r: 50,
          b: 150,
          t: 100,
          pad: 10
        },
        annotations: annotations, 
      }}
    />
  );
};






const BenchmarkingEfficiency = () => {
  const [isOverlayMenuAnalysisMenuOpen, setOverlayMenuAnalysisMenuOpen] =
    useState(false);
  const navigate = useNavigate();

 

  const closeOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(false);
  }, []);

  const onHomeIcon1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onProjectsClick = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onUsersClick = useCallback(() => {
    navigate("/view-users-super");
  }, [navigate]);

  const onButtonGeneralVariant4ContainerClick = useCallback(() => {
    navigate("/uuuid");
  }, [navigate]);

  const onButtonGeneralVariant4Container1Click = useCallback(() => {
    navigate("/show-benchmarking-charts");
  }, [navigate]);

  const onButtonGeneralVariant4Container2Click = useCallback(() => {
    navigate("/uuuid");
  }, [navigate]);

  const onButtonGeneralVariant6ContainerClick = useCallback(() => {
    navigate("/vvvid");
  }, [navigate]);

  const onButtonGeneralVariant5ContainerClick = useCallback(() => {
    navigate("/wwwid");
  }, [navigate]);

  const onButtonGeneralVariant4Container3Click = useCallback(() => {
    navigate("/tttid");
  }, [navigate]);

  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedCompetitors, setSelectedCompetitors] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedStudy, setSelectedStudy] = useState([]);

  //For Competitor Projects

  const [competitorProjects, setCompetitorProjects] = useState([]);
  const [selectedCompetitorProject, setSelectedCompetitorProject] = useState([]);

  useEffect(() => {
    if (selectedCompetitors && selectedCompetitors.length > 0) {
        fetch(`${config.server_url}/getCompetitorProjects`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ clients: selectedCompetitors })
        })
        .then(response => response.json())
        .then(data => {
            // Convert data into the format expected by the Select component
            const formattedData = [];
            for (const [competitor, projects] of Object.entries(data)) {
                formattedData.push({
                    label: competitor,
                    options: projects.map(project => ({ value: project, label: project }))
                });
            }
            setCompetitorProjects(formattedData);
        })
        .catch(error => console.error("Error fetching competitor projects:", error));
    }
}, [selectedCompetitors]);




 


  

  const [taskAndProcessData, setTaskAndProcessData] = useState([]);
  const [nvaData, setNvaData] = useState([]);
  const [efficiencyIndexData, setEfficiencyIndexData] = useState([]);
  const [paceData, setpaceData] = useState([]);

  //State for error handling 

  const [errorMessage, setErrorMessage] = useState(null);
  const [showError, setShowError] = useState(false);


  
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // loading state for chart generation
  




  //checkbox states 

  const [selectedOption, setSelectedOption] = useState("");


  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  //state for date range picker

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  


  const handleCompetitorsChange = (selectedOptions) => {
    setSelectedCompetitors(selectedOptions.map(option => option.value));
  };
  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption.value);
  };
  const handleIndustryChange = (selectedOption) => {
    setSelectedIndustry(selectedOption.value);
  };
  const handleSectorChange = (selectedOption) => {
    setSelectedSector(selectedOption.value);
  };
  const handleProjectsChange = (selectedOption) => {
    setSelectedProjects(selectedOption.value);
  };
  const handleStudyChange = (selectedOption) => {
    setSelectedStudy(selectedOption.value);
  };
  const handleCompetitorProjectsChange = (selectedOptions) => {
    setSelectedCompetitorProject(selectedOptions.map(option => option.value));
  };
  


  

   //for overlay menu

   const [overlayPosition, setOverlayPosition] = useState(null);
   const analysisIconRef = useRef(null);
   
 
   const openOverlayMenuAnalysisMenu = useCallback(() => {
     const rect = analysisIconRef.current.getBoundingClientRect();
     setOverlayPosition({
       top: rect.top + window.scrollY + rect.height,
       left: rect.left + window.scrollX,
     });
     setOverlayMenuAnalysisMenuOpen(true);
   }, []);

  
  const [chartData, setChartData] = useState([]);
  const urlMap = {
    "Customer": `${config.server_url}/processEfficiencyData`,
    "Task & Process": `${config.server_url}/processtaskData`,
    "NVA": `${config.server_url}/processNvaData`,
    "Efficiency Index": `${config.server_url}/processEfficiencyIndexData`,
    "Pace Rating": `${config.server_url}/processPaceData`,
  };

  const colorMap = {
    "Customer": '#008649',
    "Task & Process": '#293771',
    "NVA": '#d60e41',
    "Efficiency Index": '#d0d0d0',
    "Pace Rating": '#0bbbef',
  };

  useEffect(() => {
    setChartData([]);
  }, [selectedOption]);
  

  const handleGenerateClick = async () => {
    setErrorMessage(null);
    setShowError(false);
  
    // Validation
    if (!selectedCompetitors || selectedCompetitors.length === 0) {
      setErrorMessage("Please select competitors");
      setShowError(true);
      return;
    }
  
    if (!startDate || !endDate) {
      setErrorMessage("Please select date range");
      setShowError(true);
      return;
    }
    
    // Convert the dates to strings
    const start = startDate ? moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null;
    const end = endDate ? moment(endDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null;
    const clients = [...selectedCompetitors, selectedClient];
    const projects = selectedProjects;
    const c_projects = selectedCompetitorProject;
  
    setIsLoading(true);
  
    if (selectedOption === "All Charts") {
      // Fetch data for all charts
      for (const [option, url] of Object.entries(urlMap)) {
        const data = await fetchChartData(url, clients, selectedClient, projects, c_projects, start, end);
        if (data && data.error) {
          setErrorMessage(data.error);
          setShowError(true);
          setIsLoading(false);
          return;
        }
  
        if (option === "Efficiency Index") {
          setEfficiencyIndexData(data);
        } else if (option === "Pace Rating") {
          setpaceData(data);
        } else if (option === "Customer") {
          setChartData(data);
        } else if (option === "Task & Process") {
          setTaskAndProcessData(data);
        } else if (option === "NVA") {
          setNvaData(data);
        }
      }
    } else {
      // Existing logic for individual charts
      const url = urlMap[selectedOption];
      const color = colorMap[selectedOption];
      const projects = selectedProjects;
      const c_projects = selectedCompetitorProject;
      const study = selectedStudy;
      const industry_avg = chartData['industry_avg'];
      const sector_avg = chartData['sector_avg'];
  
      if (url) {
        const data = await fetchChartData(url, clients, selectedClient, projects, c_projects,  start, end, industry_avg, sector_avg);
        if (data && data.error) {
          setErrorMessage(data.error);
          setShowError(true);
          setIsLoading(false);
          return;
        }
        
        if (selectedOption === "Efficiency Index") {
          setEfficiencyIndexData(data);
        } else if (selectedOption === "Pace Rating") {
          setpaceData(data);
        } else if (selectedOption === "Customer") {
          setChartData(data);
        }
        else if (selectedOption === "Task & Process") {
          setTaskAndProcessData(data);
        } else if (selectedOption === "NVA") {
          setNvaData(data);
        }
      }
    }
  
    setIsLoading(false);
  };
  
  
  

  //Clear All contents Button Handler
  const handleClearClick = () => {
    // Clear all dropdown selections
    setSelectedClient(null);
    setSelectedCompetitors(null);
    setStartDate("");
    setEndDate("");
    setSelectedIndustry(null);
    setSelectedSector(null);
    setChartData([]);
    setSelectedProjects(null);
    setSelectedStudy(null);
    setCompetitorProjects([]);
    
    
    
    // Clear chart data depending on what was selected before clearing
    if (selectedOption === "Customer") {
      setChartData([]);
    } else if (selectedOption === "Task & Process") {
      setTaskAndProcessData([]);
    } else if (selectedOption === "NVA") {
      setNvaData([]);
    }
      else if (selectedOption === "Efficiency Index") {
      setEfficiencyIndexData([]);
    }
      else if (selectedOption === "Pace Rating") {
      setpaceData([]);
    }
    
    // Clear selected option
    setSelectedOption("");
  };
  
  

  

// Update the data source URLs for the competitors, industries, and sectors
const [clients, clientsLoading] = useFetch(`${config.server_url}/getClients`);
const [competitors, competitorsLoading] = useFetch(selectedClient ? `${config.server_url}/getCompetitors/` : null);
const [industries, industriesLoading] = useFetch(selectedClient ? `${config.server_url}/getIndustries/${selectedClient}` : null);
const [sectors, sectorsLoading] = useFetch(selectedClient ? `${config.server_url}/getSectors/${selectedClient}` : null);
const [projects, projectsLoading] = useFetch(selectedClient ? `${config.server_url}/getProjects/${selectedClient}` : null);
const [study, studyLoading] = useFetch(selectedClient ? `${config.server_url}/getStudy/${selectedClient}` : null);



 

  
  
  return (
    <>
      <div className={styles.benchmarkingEfficiency}>
        <div className={styles.dropdownsContainer}>
        <Select 
          value={clients.find(option => option.value === selectedClient) || ''}
          options={clients}
          className={styles.dropdown}
          placeholder="SELECT CLIENT"
          onChange={handleClientChange}
        />
        <Select 
          value={(projects.find(option => option.value === selectedProjects) || '')}
          options={projects}
          className={styles.dropdown}
          
          placeholder="SELECT CLIENT PROJECT"
          onChange={handleProjectsChange}
      />
        <div className="competitorContainer">
          <Select 
              value={(selectedCompetitors || []).map(item => competitors.find(option => option.value === item))}
              options={competitors}
              className={styles.dropdownCompetitor}
              isMulti
              placeholder="SELECT BECHMARKING ORGANIZATIONS"
              onChange={handleCompetitorsChange}
            />
        </div>
        
        
        <Select 
          value={selectedCompetitorProject.map(item => {
            for (const group of competitorProjects) {
              const option = group.options.find(option => option.value === item);
              if (option) {
                return option;
              }
            }
            return null;
          })}
          options={competitorProjects}
          className={styles.dropdown}
          isMulti
          placeholder="SELECT BENCHMARK PROJECTS"
          onChange={handleCompetitorProjectsChange}
          
      />




        

      
        
        <input
          type="date"
          className={styles.dateInputContainer}
          value={startDate}
          placeholder="Start Date (YYYY-MM-DD)"
          onChange={e => setStartDate(e.target.value)}
        />
        <input
          type="date"
          className={styles.dateInputContainer}
          value={endDate}
          placeholder="End Date (YYYY-MM-DD)"
          onChange={e => setEndDate(e.target.value)}
        />

        



       <button type="button" onClick={handleClearClick} className={styles.clearButton}>
                  Clear
         
        </button>
        
      </div>
      <div className={styles.checkboxContainer}>
        <label>
          <input type="radio" value="Customer" checked={selectedOption === "Customer"} onChange={handleOptionChange} />
          Customer
        </label>
        <label>
          <input type="radio" value="Task & Process" checked={selectedOption === "Task & Process"} onChange={handleOptionChange} />
          Task & Process
        </label>
        <label>
          <input type="radio" value="NVA" checked={selectedOption === "NVA"} onChange={handleOptionChange} />
          NVA
        </label>
        <label>
          <input type="radio" value="Efficiency Index" checked={selectedOption === "Efficiency Index"} onChange={handleOptionChange} />
          Efficiency Index
        </label>
        <label>
          <input type="radio" value="Pace Rating" checked={selectedOption === "Pace Rating"} onChange={handleOptionChange} />
          Pace Rating
        </label>
        <label>
          <input type="radio" value="All Charts" checked={selectedOption === "All Charts"} onChange={handleOptionChange} />
          All Charts
        </label>


    </div>
    {showError && (
        <PortalPopup
          placement="Centered"
          onOutsideClick={() => setShowError(false)}
        >
          <div className={styles.errorMessageBox}>
            <p className={styles.errorMessage}>{errorMessage}</p>
            <button className={styles.closeErrorButton} onClick={() => setShowError(false)}>Close</button>
          </div>
        </PortalPopup>
      )}


    {isLoading && (
        <div className={styles.loadingContainer}>
          <Oval color="#a2c73b" height={80} width={80} />
        </div>
      )}
    <div className={styles.chartContainer}>
        {selectedOption === "All Charts" && (
          <>
            {chartData.length > 0 && 
              plotChart(chartData, "Percentage of Customer", colorMap["Customer"], selectedClient, "Customer")}
            {taskAndProcessData.length > 0 && 
              plotChart(taskAndProcessData, "Percentage of Task & Process", colorMap["Task & Process"], selectedClient, "Task & Process")}
            {nvaData.length > 0 && 
              plotChart(nvaData, "Percentage of NVA", colorMap["NVA"], selectedClient, "NVA")}
            {efficiencyIndexData.length > 0 && 
              plotChart(efficiencyIndexData, "Percentage of Efficiency Index", colorMap["Efficiency Index"], selectedClient, "Efficiency Index", 'efficiency_index')}
            {paceData.length > 0 && 
              plotChart(paceData, "Percentage of Pace Rating", colorMap["Pace Rating"], selectedClient, "Pace Rating", 'avg_pace')}
            
          </>
        )}
        {selectedOption === "Efficiency Index" && efficiencyIndexData.length > 0 && 
          plotChart(efficiencyIndexData, `Percentage of ${selectedOption}`, colorMap[selectedOption], selectedClient, selectedOption, 'efficiency_index')}
        {selectedOption === "Pace Rating" && paceData.length > 0 && 
          plotChart(paceData, `Percentage of ${selectedOption}`, colorMap[selectedOption], selectedClient, selectedOption, 'avg_pace')}
        {selectedOption === "Customer" && chartData.length > 0 && 
          plotChart(chartData, `Percentage of ${selectedOption}`, colorMap[selectedOption], selectedClient, selectedOption)}
        {selectedOption === "Task & Process" && taskAndProcessData.length > 0 && 
          plotChart(taskAndProcessData, `Percentage of ${selectedOption}`, colorMap[selectedOption], selectedClient, selectedOption)}
        {selectedOption === "NVA" && nvaData.length > 0 && 
          plotChart(nvaData, `Percentage of ${selectedOption}`, colorMap[selectedOption], selectedClient, selectedOption)}
      </div>

        
        <div className={styles.universalsidebar}>
          <div className={styles.universalsidebar1} />
          
          <img className={styles.logoIcon} alt="" src="/logo.svg" />
          <img
            className={styles.homeIcon1}
            alt=""
            src="/home.svg"
            onClick={onHomeIcon1Click}
          />
          <img
            className={styles.projectsIcon}
            alt=""
            src="/projects.svg"
            onClick={onProjectsClick}
          />
          <img
            className={styles.usersIcon}
            alt=""
            src="/users.svg"
            onClick={onUsersClick}
          />
          <img
            className={styles.analysisIcon}
            ref={analysisIconRef}
            alt=""
            src="/analysis.svg"
            onClick={openOverlayMenuAnalysisMenu}
          />
          <img className={styles.settingsIcon} alt="" src="/settings.svg" />
        </div>
        <div className={styles.topBar}>
          <b className={styles.topBarText}>Efficiency Study Benchmarking</b>
          <div className={styles.topBarChild} />
          <img className={styles.vectorIcon} alt="" src="/vector1.svg" />
          <div className={styles.search}>Search...</div>
          <img className={styles.topBarItem} alt="" src="/ellipse-20@2x.png" />
          <b className={styles.loggedUser}>Rushan Arshad</b>
          <img className={styles.vectorIcon1} alt="" src="/vector8.svg" />
        </div>
        <div className={styles.menubenchmarkingstudies} />
        
            <div
              className={styles.buttongeneralvariant4}
              onClick={onButtonGeneralVariant4ContainerClick}
            >
              <img className={styles.vectorIcon2} alt="" src="/vector2.svg" />
              <b className={styles.efficiencyStudy}>Efficiency Study</b>
            </div>
            <div
            className={styles.buttongeneralvariant6}
            onClick={onButtonGeneralVariant6ContainerClick}
          >
            <img className={styles.vectorIcon2} alt="" src="/vector4.svg" />
            <b className={styles.roleStudy}>Role Study</b>
          </div>
          <div
            className={styles.buttongeneralvariant5}
            onClick={onButtonGeneralVariant5ContainerClick}
          >
            <img className={styles.vectorIcon2} alt="" src="/vector3.svg" />
            <b className={styles.roleStudy}>Activity Study</b>
        </div>
      <div className={styles.buttongeneralvariant41}>
          <button type="button" onClick={handleGenerateClick}>
            <img className={styles.vectorIcon2} alt="" src="/vector2.svg" />
            <b className={styles.efficiencyStudy1}>Generate</b>
          </button>
        </div>
        
        <b className={styles.generate}>Generate</b>
        <b className={styles.efficiencyIndex}>Efficiency Index</b>
        <div
          className={styles.buttongeneralvariant43}
          onClick={onButtonGeneralVariant4Container3Click}
        >
          <img className={styles.vectorIcon2} alt="" src="/vector9.svg" />
          <b className={styles.back}>Back</b>
        </div>
      </div>
      
      {isOverlayMenuAnalysisMenuOpen && overlayPosition && (
        <PortalPopup
          style={{
            position: 'absolute',
            top: `${overlayPosition.top}px`,
            left: `${overlayPosition.left}px`,
          }}
          onOutsideClick={closeOverlayMenuAnalysisMenu}
        >
          <OverlayMenuAnalysisMenu onClose={closeOverlayMenuAnalysisMenu} />
        </PortalPopup>
      )}
      
        
    </>
  );
};

export default BenchmarkingEfficiency; 
