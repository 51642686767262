import { useState, useCallback, useEffect } from "react";
import OverlayMenuAnalysisMenu from "../components/OverlayMenuAnalysisMenu";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./UpdateUser.module.css";

const UpdateUser = () => {
  const [isOverlayMenuAnalysisMenuOpen, setOverlayMenuAnalysisMenuOpen] =
    useState(false);
    const navigate = useNavigate();

  const openOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(true);
  }, []);

  const closeOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(false);
  }, []);

  const onButtonContainer3Click = useCallback(() => {
    navigate("/add-user");
  }, [navigate]);

  const onButtonContainer4Click = useCallback(() => {
    navigate("/add-user2");
  }, [navigate]);

  const onHomeIcon1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onProjectsClick = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onUsersClick = useCallback(() => {
    navigate("/view-users-super");
  }, [navigate]);
  const goBack = useCallback(() => {
    navigate("/superusermenu");
  }, [navigate]);

  const [formData, setFormData] = useState({
    username: '',
    password: '',
    email: '',
    userType: '',
    subEnd: '',
    firstName: '',
    lastName: '',
    company: '',
    industry: ''
  });
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [userType, setUserType] = useState('');
  const [subEnd, setSubEnd] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [industry, setIndustry] = useState('');
  const [usernames, setUsernames] = useState([]);
  
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    const fetchUsernames = async () => {
      const response = await fetch('http://localhost:5000/api/usernames');
      const data = await response.json();
      setUsernames(data);
    };
  
    fetchUsernames();
  }, []);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const response = await fetch(`http://localhost:5000/api/updateUser/${username}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        password: password,
        email: email,
        userType: userType,
        subEnd: subEnd,
        firstName: firstName,
        lastName: lastName,
        company: company,
        industry: industry
      })
    });
  
    if (response.ok) {
      alert('User updated successfully');
    } else {
      alert('Failed to update user');
    }
};

  const [lastActivity, setLastActivity] = useState(Date.now());

  const handleActivity = () => {
    setLastActivity(Date.now());
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (Date.now() - lastActivity > 60000) { // 1 minute
        // Logout the user
        navigate('/');
      }
    }, 600000); // Check every minute

    // Listen for any user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    // Cleanup
    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [lastActivity, navigate]);

  const handleClear = () => {
    
      setUsername(null),
      setPassword(null),
      setEmail(null),
      setUserType(null),
      setSubEnd(null),
      setFirstName(null),
      setLastName(null),
      setCompany(null),
      setIndustry(null)
    
  };
  return (
    <>
      <div className={styles.addUser1}>
        
        <div className={styles.formContainer}>
        <form onSubmit={handleSubmit}>
        <div className={styles.fieldContainer}>
        <label className={styles.formLabel}>Username:</label>
                    <select name="username" className={styles.formInput} onChange={e => setUsername(e.target.value)}>
            {usernames.map((username, index) => (
                <option key={index} value={username}>{username}</option>
            ))}
            </select>
        </div>

        <div className={styles.fieldContainer}>
          <label className={styles.formLabel}>Password:</label>
          <input type="password" name="password" className={styles.formInput} 
                  value={password}
                  onChange={e => setPassword(e.target.value)} />
        </div>

        <div className={styles.fieldContainer}>
          <label className={styles.formLabel}>Email:</label>
          <input type="email" name="email" className={styles.formInput}
                value={email}
                onChange={e => setEmail(e.target.value)} />
        </div>

        <div className={styles.fieldContainer}>
          <label className={styles.formLabel}>User Type:</label>
          <input type="text" name="userType" className={styles.formInput} 
                value={userType}
                onChange={e => setUserType(e.target.value)} />
        </div>

        <div className={styles.fieldContainer}>
          <label className={styles.formLabel}>Sub_End:</label>
          <input type="text" name="subEnd" className={styles.formInput} 
                value={subEnd}
                onChange={e => setSubEnd(e.target.value)} />
        </div>

        <div className={styles.fieldContainer}>
          <label className={styles.formLabel}>First Name:</label>
          <input type="text" name="firstName" className={styles.formInput} 
              value={firstName}
              onChange={e => setFirstName(e.target.value)} />
        </div>

        <div className={styles.fieldContainer}>
          <label className={styles.formLabel}>Last Name:</label>
          <input type="text" name="lastName" className={styles.formInput} 
              value={lastName}
              onChange={e => setLastName(e.target.value)} />
        </div>

        <div className={styles.fieldContainer}>
          <label className={styles.formLabel}>Company:</label>
          <input type="text" name="company" className={styles.formInput} 
                  value={company}
                  onChange={e => setCompany(e.target.value)}/>
        </div>

        <div className={styles.fieldContainer}>
          <label className={styles.formLabel}>Industry:</label>
          <input type="text" name="industry" className={styles.formInput} 
                value={industry}
                onChange={e => setIndustry(e.target.value)} />
        </div>

        <button type="submit" className={styles.addButton}>Update User</button>
        <button type="button" className={styles.backButton} onClick={goBack}>Back</button>
        <button type="button" className={styles.clearButton} onClick={handleClear}>Clear</button>
      </form>
      </div>
          
        <div className={styles.universalsidebar}>
          <div className={styles.universalsidebar1} />
          
          <img className={styles.logoIcon} alt="" src="/logo3.svg" />
          <img
            className={styles.homeIcon1}
            alt=""
            src="/home.svg"
            onClick={onHomeIcon1Click}
          />
          <img
            className={styles.projectsIcon}
            alt=""
            src="/projects.svg"
            onClick={onProjectsClick}
          />
          <img
            className={styles.usersIcon}
            alt=""
            src="/users.svg"
            onClick={onUsersClick}
          />
          <img
            className={styles.analysisIcon}
            alt=""
            src="/analysis.svg"
            onClick={openOverlayMenuAnalysisMenu}
          />
          <img className={styles.settingsIcon} alt="" src="/settings.svg" />
        </div>
        <div className={styles.topBar}>
          <b className={styles.topBarText}>Add New User</b>
          <div className={styles.topBarChild} />
          <img className={styles.vectorIcon} alt="" src="/vector1.svg" />
          <div className={styles.search}>Search...</div>
          <img className={styles.topBarItem} alt="" src="/ellipse-20@2x.png" />
          <b className={styles.loggedUser}>Rushan Arshad</b>
          <img className={styles.vectorIcon1} alt="" src="/vector8.svg" />
        </div>
        <b className={styles.topBarText1}>Add New User</b>
        </div>
        
      
      {isOverlayMenuAnalysisMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeOverlayMenuAnalysisMenu}
        >
          <OverlayMenuAnalysisMenu onClose={closeOverlayMenuAnalysisMenu} />
        </PortalPopup>
      )}
    </>
  );
};

export default UpdateUser;
