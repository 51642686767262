import { useRef, useState, useCallback } from "react";
import OverlayMenuAnalysisMenu from "../components/OverlayMenuAnalysisMenu";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./SuperUserMenu.module.css";
const SuperUserMenu = () => {
  const [isOverlayMenuAnalysisMenuOpen, setOverlayMenuAnalysisMenuOpen] =
    useState(false);
  const navigate = useNavigate();

 

  const closeOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(false);
  }, []);

  const onHomeIcon1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onProjectsClick = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onUsersClick = useCallback(() => {
    navigate("/view-users-super");
  }, [navigate]);

  const onButtonGeneralVariant4ContainerClick = useCallback(() => {
    navigate("/view-users-super");
  }, [navigate]);

  const onButtonGeneralVariant4Container1Click = useCallback(() => {
    navigate("/add-user1");
  }, [navigate]);

  const onButtonGeneralVariant4Container4Click = useCallback(() => {
    navigate("/loginwindow");
  }, [navigate]);
  const onButtonGeneralVariant4Container42Click = useCallback(() => {
    navigate("/UpdateUser");
  }, [navigate]);

  //for overlay menu

  

  
  const [overlayPosition, setOverlayPosition] = useState(null);
  const analysisIconRef = useRef(null);
  

  const openOverlayMenuAnalysisMenu = useCallback(() => {
    const rect = analysisIconRef.current.getBoundingClientRect();
    setOverlayPosition({
      top: rect.top + window.scrollY + rect.height,
      left: rect.left + window.scrollX,
    });
    setOverlayMenuAnalysisMenuOpen(true);
  }, []);

  return (
    <>
      <div className={styles.superUserMenu}>
        <div className={styles.superUserMenuChild} />
        <div className={styles.superUserMenuItem} />
        <div className={styles.notification}>
          <div className={styles.day}>1 day</div>
          <div className={styles.days}>4 days</div>
        </div>
        <div className={styles.notification1}>
          <div className={styles.fileUploaded}>File uploaded</div>
          <div className={styles.efficiencyStudy1Has}>
            Efficiency Study1 has successfully been uploaded
          </div>
          <img
            className={styles.notification1Child}
            alt=""
            src="/group-258.svg"
          />
        </div>
        <div className={styles.notification2}>
          <div className={styles.passwordReset}>Password reset</div>
          <div className={styles.user1HasReset}>
            User1 has reset the password
          </div>
          <img
            className={styles.notification1Child}
            alt=""
            src="/group-260.svg"
          />
        </div>
        <div className={styles.superUserMenuInner} />
        <div className={styles.rectangleDiv} />
        <div className={styles.superUserMenuChild1} />
        <div className={styles.viewAll}>View All</div>
        <div className={styles.day1}>1 day</div>
        <div className={styles.days1}>4 days</div>
        <div className={styles.mariosParent}>
          <div className={styles.marios}>Marios</div>
          <div className={styles.exportedVisualisations}>
            Exported visualisations
          </div>
          <img className={styles.frameChild} alt="" src="/ellipse-40@2x.png" />
        </div>
        <div className={styles.ellie}>Ellie</div>
        <div
          className={styles.requestedAccessTo}
        >{`Requested access to analysis files. `}</div>
        <img className={styles.ellipseIcon} alt="" src="/ellipse-41@2x.png" />
        <div className={styles.superUserMenuChild2} />
        <div className={styles.superUserMenuChild3} />
        <div className={styles.universalsidebar}>
          <div className={styles.universalsidebar1} />
          <img className={styles.homeIcon} alt="" />
          <img className={styles.logoIcon} alt="" src="/logo.svg" />
          <img
            className={styles.homeIcon1}
            alt=""
            src="/home.svg"
            onClick={onHomeIcon1Click}
          />
          <img
            className={styles.projectsIcon}
            alt=""
            src="/projects.svg"
            onClick={onProjectsClick}
          />
          <img
            className={styles.usersIcon}
            alt=""
            src="/users.svg"
            onClick={onUsersClick}
          />
          <img
            className={styles.analysisIcon}
            ref={analysisIconRef}
            alt=""
            src="/analysis.svg"
            onClick={openOverlayMenuAnalysisMenu}
          />
          <img className={styles.settingsIcon} alt="" src="/settings.svg" />
        </div>
        <div className={styles.topBar}>
          <b className={styles.topBarText}>Welcome, Super User</b>
          <div className={styles.topBarChild} />
          <img className={styles.vectorIcon} alt="" src="/vector1.svg" />
          <div className={styles.search}>Search...</div>
          <img className={styles.topBarItem} alt="" src="/ellipse-20@2x.png" />
          <b className={styles.loggedUser}>Rushan Arshad</b>
          <img className={styles.vectorIcon1} alt="" src="/vector8.svg" />
        </div>
        <div
          className={styles.buttongeneralvariant4}
          onClick={onButtonGeneralVariant4ContainerClick}
        >
          <img className={styles.vectorIcon2} alt="" src="/vector9.svg" />
          <b className={styles.viewUsers}>View Users</b>
          <img className={styles.userIcon} alt="" src="/user1.svg" />
        </div>
        <div
          className={styles.buttongeneralvariant41}
          onClick={onButtonGeneralVariant4Container1Click}
        >
          <img className={styles.vectorIcon2} alt="" src="/vector9.svg" />
          <b className={styles.viewUsers}>Add Users</b>
          <img className={styles.adduserIcon} alt="" src="/adduser1.svg" />
        </div>
        <div className={styles.buttongeneralvariant42} onClick={onButtonGeneralVariant4Container42Click}>
            
          <img className={styles.vectorIcon2} alt="" src="/vector9.svg" />
          <b className={styles.viewUsers}>Edit Users</b>
          <img className={styles.userIcon} alt="" src="/user1.svg" />
          
        </div>
        <div className={styles.buttongeneralvariant43}>
          <img className={styles.vectorIcon2} alt="" src="/vector9.svg" />
          <b className={styles.viewUsers}>Manage Roles</b>
          <img className={styles.userIcon} alt="" src="/user1.svg" />
        </div>
        <div
          className={styles.buttongeneralvariant44}
          onClick={onButtonGeneralVariant4Container4Click}
        >
          <img className={styles.vectorIcon2} alt="" src="/vector9.svg" />
          <b className={styles.viewUsers}>Logout</b>
        </div>
        <b className={styles.notifications}>Notifications</b>
      </div>
      {isOverlayMenuAnalysisMenuOpen && overlayPosition && (
        <PortalPopup
          style={{
            position: 'absolute',
            top: `${overlayPosition.top}px`,
            left: `${overlayPosition.left}px`,
          }}
          onOutsideClick={closeOverlayMenuAnalysisMenu}
        >
          <OverlayMenuAnalysisMenu onClose={closeOverlayMenuAnalysisMenu} />
        </PortalPopup>
      )}
    </>
  );
};

export default SuperUserMenu;
