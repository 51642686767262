import { useRef,useState, useCallback } from "react";
import OverlayMenuAnalysisMenu from "../components/OverlayMenuAnalysisMenu";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./BenchmarkingAnalysis.module.css";
const BenchmarkingAnalysis = () => {
  const [isOverlayMenuAnalysisMenuOpen, setOverlayMenuAnalysisMenuOpen] =
    useState(false);
  const navigate = useNavigate();

  

  const closeOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(false);
  }, []);

  const onHomeIcon1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onProjectsClick = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onUsersClick = useCallback(() => {
    navigate("/view-users-super");
  }, [navigate]);

  const onButtonGeneralVariant4ContainerClick = useCallback(() => {
    navigate("/uuuid");
  }, [navigate]);

  const onButtonGeneralVariant5ContainerClick = useCallback(() => {
    navigate("/wwwid");
  }, [navigate]);

  const onButtonGeneralVariant6ContainerClick = useCallback(() => {
    navigate("/vvvid");
  }, [navigate]);

  const onButtonGeneralVariant4Container1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  //for overlay menu

  const [overlayPosition, setOverlayPosition] = useState(null);
  const analysisIconRef = useRef(null);
  

  const openOverlayMenuAnalysisMenu = useCallback(() => {
    const rect = analysisIconRef.current.getBoundingClientRect();
    setOverlayPosition({
      top: rect.top + window.scrollY + rect.height,
      left: rect.left + window.scrollX,
    });
    setOverlayMenuAnalysisMenuOpen(true);
  }, []);


  return (
    <>
      <div className={styles.benchmarkingAnalysis}>
        <div className={styles.benchmarkingAnalysisChild} />
        <div className={styles.universalsidebar}>
          <div className={styles.universalsidebar1} />
          
          <img className={styles.logoIcon} alt="" src="/logo1.svg" />
          <img
            className={styles.homeIcon1}
            alt=""
            src="/home.svg"
            onClick={onHomeIcon1Click}
          />
          <img
            className={styles.projectsIcon}
            alt=""
            src="/projects.svg"
            onClick={onProjectsClick}
          />
          <img
            className={styles.usersIcon}
            alt=""
            src="/users.svg"
            onClick={onUsersClick}
          />
          <img
            className={styles.analysisIcon}
            ref={analysisIconRef}
            alt=""
            src="/analysis.svg"
            onClick={openOverlayMenuAnalysisMenu}
          />
          <img className={styles.settingsIcon} alt="" src="/settings.svg" />
        </div>
        <div className={styles.topBar}>
          <b className={styles.topBarText}>Benchmarking Analysis</b>
          <div className={styles.topBarChild} />
          <img className={styles.vectorIcon} alt="" src="/vector1.svg" />
          <div className={styles.search}>Search...</div>
          <img className={styles.topBarItem} alt="" src="/ellipse-20@2x.png" />
          <b className={styles.loggedUser}>Rushan Arshad</b>
          <img className={styles.vectorIcon1} alt="" src="/vector8.svg" />
        </div>
        <div className={styles.menubenchmarkingstudies} />
        <div
          className={styles.buttongeneralvariant4}
          onClick={onButtonGeneralVariant4ContainerClick}
        >
          <img className={styles.vectorIcon2} alt="" src="/vector2.svg" />
          <b className={styles.efficiencyStudy}>Efficiency Study</b>
        </div>
        <div
          className={styles.buttongeneralvariant5}
          onClick={onButtonGeneralVariant5ContainerClick}
        >
          <img className={styles.vectorIcon2} alt="" src="/vector3.svg" />
          <b className={styles.activityStudy}>Activity Study</b>
        </div>
        <div
          className={styles.buttongeneralvariant6}
          onClick={onButtonGeneralVariant6ContainerClick}
        >
          <img className={styles.vectorIcon2} alt="" src="/vector4.svg" />
          <b className={styles.activityStudy}>Role Study</b>
        </div>
        <div
          className={styles.buttongeneralvariant41}
          onClick={onButtonGeneralVariant4Container1Click}
        >
          <img className={styles.vectorIcon2} alt="" src="/vector9.svg" />
          <b className={styles.logout}>Back</b>
        </div>
        <b className={styles.back}>Back</b>
      </div>
      {isOverlayMenuAnalysisMenuOpen && overlayPosition && (
        <PortalPopup
          style={{
            position: 'absolute',
            top: `${overlayPosition.top}px`,
            left: `${overlayPosition.left}px`,
          }}
          onOutsideClick={closeOverlayMenuAnalysisMenu}
        >
          <OverlayMenuAnalysisMenu onClose={closeOverlayMenuAnalysisMenu} />
        </PortalPopup>
      )}
    </>
  );
};

export default BenchmarkingAnalysis;
