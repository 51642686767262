import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SectorMenu.module.css";
const SectorMenu = ({ onClose }) => {
  const navigate = useNavigate();

  const onDropdownMenuOption1Click = useCallback(() => {
    navigate("/core-analysis-efficiency-sector1");
  }, [navigate]);

  const onDropdownMenuOption3Click = useCallback(() => {
    navigate("/core-analysis-menu");
  }, [navigate]);

  return (
    <div className={styles.sectorMenu}>
      <div className={styles.dropdownMenuContents}>
        <div className={styles.dropdownMenuOption}>
          <div className={styles.byMonth}>All Industries</div>
        </div>
        <div
          className={styles.dropdownMenuOption1}
          onClick={onDropdownMenuOption1Click}
        >
          <div className={styles.byMonth}>Telecommunications</div>
        </div>
        <div className={styles.dropdownMenuOption2}>
          <div className={styles.byMonth}>Clinic</div>
        </div>
        <div
          className={styles.dropdownMenuOption3}
          onClick={onDropdownMenuOption3Click}
        >
          <div className={styles.byMonth}>Core Analysis</div>
        </div>
        <div className={styles.dropdownMenuOption4}>
          <div className={styles.byMonth}>Data Exploration</div>
        </div>
        <div className={styles.dropdownMenuOption2}>
          <div className={styles.byMonth} />
        </div>
      </div>
    </div>
  );
};

export default SectorMenu;
