import styles from "./BxbxChevronDown.module.css";
const BxbxChevronDown = () => {
  return (
    <div className={styles.bxbxChevronDown}>
      <img className={styles.vectorIcon} alt="" src="/vector10.svg" />
    </div>
  );
};

export default BxbxChevronDown;
