import { useMemo, useEffect } from "react";
import { createPortal } from "react-dom";
import styles from "./PortalPopup.module.css";

const PortalPopup = ({
  children,
  overlayColor,
  zIndex = 100,
  style = {},
  onOutsideClick,
}) => {
  const popupStyle = useMemo(() => {
    const style = {
      zIndex,
      opacity: 1,
      alignItems: "center",
      justifyContent: "center",
    };

    if (overlayColor) {
      style.backgroundColor = overlayColor;
    }

    return style;
  }, [overlayColor, zIndex]);

  const onOverlayClick = (e) => {
    if (onOutsideClick && e.target.classList.contains(styles.portalPopupOverlay)) {
      onOutsideClick();
    }
    e.stopPropagation();
  };

  return (
    <Portal>
      <div
        className={styles.portalPopupOverlay}
        style={popupStyle}
        onClick={onOverlayClick}
      >
        <div style={{ ...style, opacity: 1 }}>{children}</div>
      </div>
    </Portal>
  );
};

export const Portal = ({ children, containerId = "portals" }) => {
  let portalsDiv = document.getElementById(containerId);
  if (!portalsDiv) {
    portalsDiv = document.createElement("div");
    portalsDiv.setAttribute("id", containerId);
    document.body.appendChild(portalsDiv);
  }

  return createPortal(children, portalsDiv);
};

export default PortalPopup;
