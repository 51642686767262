import React, { useState } from "react";
import HeatmapGrid from "react-heatmap-grid";
import axios from 'axios';
import styles from "./simulationModule.css";

const CoreAnalysisEfficiency = () => {
  const [clientName, setClientName] = useState('');
  const [area, setArea] = useState('');
  const [duration, setDuration] = useState(0);
  const [heatmapData, setHeatmapData] = useState([]);

  const handleGenerate = async () => {
    try {
      const response = await axios.post('http://localhost:5000/api/simulation', {
        clientName,
        area,
        duration
      });
      setHeatmapData(response.data);
    } catch (error) {
      console.error('Error fetching heatmap data:', error);
    }
  };

  return (
    <div className={styles.coreAnalysisEfficiency}>
        {/* Top bar */}
        <div className={styles.topBar}>
            <img className={styles.vectorIcon} alt="" src="/vector1.svg" />
            <div className={styles.search}>Search...</div>
            <img className={styles.userIcon} alt="" src="/ellipse-20@2x.png" />
            <span className={styles.userName}>Rushan Arshad</span>
        </div>

        {/* Sidebar */}
        <div className={styles.sidebar}>
            <img className={styles.logoIcon} alt="" src="/logo.svg" />
            {/* ... other sidebar icons and elements ... */}
        </div>

        {/* Main content area */}
        <div className={styles.contentArea}>
          <select onChange={e => setClientName(e.target.value)}>
            <option value="client1">Client 1</option>
            <option value="client2">Client 2</option>
          </select>

          <select onChange={e => setArea(e.target.value)}>
            <option value="area1">Area 1</option>
            <option value="area2">Area 2</option>
          </select>

          <input 
            type="number" 
            value={duration} 
            onChange={e => setDuration(e.target.value)} 
            placeholder="Duration" 
          />

          <button onClick={handleGenerate}>Generate</button>

          {/* Heatmap Visualization */}
          <HeatmapGrid
            xLabels={['Label1', 'Label2', 'Label3']}
            yLabels={['LabelA', 'LabelB', 'LabelC']}
            data={heatmapData}
          />
        </div>
    </div>
  );
};

export default CoreAnalysisEfficiency;
