import { useRef,useEffect,useState, useCallback } from "react";
import OverlayMenuAnalysisMenu from "../components/OverlayMenuAnalysisMenu";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./BenchmarkingActivity.module.css";
import Select from 'react-select';
import Plot from 'react-plotly.js';
import { Oval } from 'react-loader-spinner';

import moment from 'moment';

const useFetch = (url) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchUrl = async () => {
    try {
      const response = await fetch(url);
      const rawData = await response.json();
      
      const formattedData = rawData.map(item  => {
        // Split the string at the hyphen and capitalize the first letter of the second part
        const parts = item.split('-');
        if (parts.length > 1) {
          parts[1] = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
        }
        
        const formattedItem = parts.join(' ');
        
        return { value: item, label: formattedItem };
      });
  
      // Sorting the data alphabetically
      const sortedData = formattedData.sort((a, b) => a.label.localeCompare(b.label));
  
      setData(sortedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (url) {
      fetchUrl();
    }
  }, [url]);

  return [data, loading];
};

const fetchData = async (url, clients, excludedClient,tasks, projects, c_projects, start, end) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ clients, excluded_client: excludedClient, tasks, projects, c_projects, start_date: start, end_date: end}),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const BenchmarkingActivity = () => {
  const [isOverlayMenuAnalysisMenuOpen, setOverlayMenuAnalysisMenuOpen] =
    useState(false);
  const navigate = useNavigate();

  const openOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(true);
  }, []);

  const closeOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(false);
  }, []);

  const onButtonContainer1Click = useCallback(() => {
    navigate("/tttid");
  }, [navigate]);

  const onButtonGeneralVariant4ContainerClick = useCallback(() => {
    navigate("/uuuid");
  }, [navigate]);

  const onButtonGeneralVariant5ContainerClick = useCallback(() => {
    navigate("/wwwid");
  }, [navigate]);

  const onButtonGeneralVariant6ContainerClick = useCallback(() => {
    navigate("/vvvid");
  }, [navigate]);

  const onHomeIcon1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onProjectsClick = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onUsersClick = useCallback(() => {
    navigate("/view-users-super");
  }, [navigate]);

  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedCompetitors, setSelectedCompetitors] = useState([]);
  const [competitorProjects, setCompetitorProjects] = useState([]);
  const [selectedCompetitorProject, setSelectedCompetitorProject] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const[selectedTasks, setSelectedTasks]=useState([]);

  useEffect(() => {
    if (selectedCompetitors && selectedCompetitors.length > 0) {
        fetch('http://localhost:5000/api/getActivityCompetitorProjects', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ clients: selectedCompetitors })
        })
        .then(response => response.json())
        .then(data => {
            // Convert data into the format expected by the Select component
            const formattedData = [];
            for (const [competitor, projects] of Object.entries(data)) {
                formattedData.push({
                    label: competitor,
                    options: projects.map(project => ({ value: project, label: project }))
                });
            }
            setCompetitorProjects(formattedData);
        })
        .catch(error => console.error("Error fetching competitor projects:", error));
    }
}, [selectedCompetitors]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [showError, setShowError] = useState(false);




  
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // loading state for chart generation
  




  //checkbox states 

  const [selectedOption, setSelectedOption] = useState("");


  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  //state for date range picker

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  


  const handleCompetitorsChange = (selectedOptions) => {
    setSelectedCompetitors(selectedOptions.map(option => option.value));
  };
  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption.value);
  };
  const handleProjectsChange = (selectedOption) => {
    setSelectedProjects(selectedOption.value);
  };
  const handleCompetitorProjectsChange = (selectedOptions) => {
    setSelectedCompetitorProject(selectedOptions.map(option => option.value));
  };
  const handleTasksChange = (selectedOptions) => {
    setSelectedTasks(selectedOptions.map(option => option.value));
  };

  const [overlayPosition, setOverlayPosition] = useState(null);

const [clients, clientsLoading] = useFetch('http://localhost:5000/api/getActivityClients');
const [competitors, competitorsLoading] = useFetch(selectedClient ? `http://localhost:5000/api/getActivityCompetitors/${selectedClient}` : null);
const [projects, projectsLoading] = useFetch(selectedClient ? `http://localhost:5000/api/getActivityProjects/${selectedClient}` : null);
const [tasks, tasksLoading] = useFetch(selectedClient ? `http://localhost:5000/api/getActivityTasks/${selectedClient}` : null);

const handleClearClick = () => {
  // Clear all dropdown selections
  setSelectedClient(null);
  setSelectedCompetitors(null);
  setStartDate("");
  setEndDate("");
  
  setSelectedProjects(null);
  
  setCompetitorProjects([]);
  
  
  
  
  
  // Clear selected option
  setSelectedOption("");
};

  return (
    <>
      <div className={styles.benchmarkingActivity}>
      <div className={styles.dropdownsContainer}>
        <Select 
          value={clients.find(option => option.value === selectedClient) || ''}
          options={clients}
          className={styles.dropdown}
          placeholder="Select Client..."
          onChange={handleClientChange}
        />
        <div className="competitorContainer">
          <Select 
              value={(selectedCompetitors || []).map(item => competitors.find(option => option.value === item))}
              options={competitors}
              className={styles.dropdownCompetitor}
              isMulti
              placeholder="Select Competitors..."
              onChange={handleCompetitorsChange}
            />
        </div>
        <Select 
              value={(selectedTasks || []).map(item => tasks.find(option => option.value === item))}
              options={tasks}
              className={styles.dropdown}
              isMulti
              placeholder="Select Tasks..."
              onChange={handleTasksChange}
            />
        <Select 
          value={(projects.find(option => option.value === selectedProjects) || '')}
          options={projects}
          className={styles.dropdown}
          
          placeholder="Select Projects..."
          onChange={handleProjectsChange}
      />
        
        <Select 
          value={selectedCompetitorProject.map(item => {
            for (const group of competitorProjects) {
              const option = group.options.find(option => option.value === item);
              if (option) {
                return option;
              }
            }
            return null;
          })}
          options={competitorProjects}
          className={styles.dropdown}
          isMulti
          placeholder="Select Competitor Projects..."
          onChange={handleCompetitorProjectsChange}
          
      />
        
        <input
          type="date"
          className={styles.dateInputContainer}
          value={startDate}
          placeholder="Start Date (YYYY-MM-DD)"
          onChange={e => setStartDate(e.target.value)}
        />
        <input
          type="date"
          className={styles.dateInputContainer}
          value={endDate}
          placeholder="End Date (YYYY-MM-DD)"
          onChange={e => setEndDate(e.target.value)}
        />

        



       <button type="button" onClick={handleClearClick} className={styles.clearButton}>
                  Clear
         
        </button>
        </div>
        
        <div className={styles.topBarBenchmarking}>
          <b
            className={styles.activityStudyBenchmarking}
          >{`Activity Study Benchmarking `}</b>
          <div className={styles.bxbxSearchParent}>
            <img
              className={styles.bxbxSearchIcon}
              alt=""
              src="/bxbxsearch.svg"
            />
            <div className={styles.search}>Search...</div>
          </div>
          <img
            className={styles.topBarBenchmarkingChild}
            alt=""
            src="/ellipse-20@2x.png"
          />
          <b className={styles.rushanArshad}>Rushan Arshad</b>
          <img
            className={styles.bxbxChevronDownIcon}
            alt=""
            src="/bxbxchevrondown.svg"
          />
        </div>
        
        <div className={styles.button}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus2.svg" />
          <b className={styles.button1}>Generate</b>
        </div>
        <div className={styles.button2} onClick={onButtonContainer1Click}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus2.svg" />
          <b className={styles.button1}>Back</b>
        </div>
        <div className={styles.menubenchmarkingstudies} />
        <div
          className={styles.buttongeneralvariant4}
          onClick={onButtonGeneralVariant4ContainerClick}
        >
          <img className={styles.vectorIcon} alt="" src="/vector2.svg" />
          <b className={styles.efficiencyStudy}>Efficiency Study</b>
        </div>
        <div
          className={styles.buttongeneralvariant5}
          onClick={onButtonGeneralVariant5ContainerClick}
        >
          <img className={styles.vectorIcon} alt="" src="/vector3.svg" />
          <b className={styles.activityStudy}>Activity Study</b>
        </div>
        <div
          className={styles.buttongeneralvariant6}
          onClick={onButtonGeneralVariant6ContainerClick}
        >
          <img className={styles.vectorIcon} alt="" src="/vector4.svg" />
          <b className={styles.activityStudy}>Role Study</b>
        </div>
        <div className={styles.universalsidebar}>
          <div className={styles.universalsidebar1} />
          
          <img className={styles.logoIcon} alt="" src="/logo.svg" />
          <img
            className={styles.homeIcon1}
            alt=""
            src="/home.svg"
            onClick={onHomeIcon1Click}
          />
          <img
            className={styles.projectsIcon}
            alt=""
            src="/projects.svg"
            onClick={onProjectsClick}
          />
          <img
            className={styles.usersIcon}
            alt=""
            src="/users.svg"
            onClick={onUsersClick}
          />
          <img
            className={styles.analysisIcon}
            alt=""
            src="/analysis.svg"
            onClick={openOverlayMenuAnalysisMenu}
          />
          <img className={styles.settingsIcon} alt="" src="/settings.svg" />
        </div>
      </div>
      {isOverlayMenuAnalysisMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeOverlayMenuAnalysisMenu}
        >
          <OverlayMenuAnalysisMenu onClose={closeOverlayMenuAnalysisMenu} />
        </PortalPopup>
      )}
    </>
  );
};

export default BenchmarkingActivity;
