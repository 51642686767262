import { useState, useCallback } from "react";
import OverlayMenuAnalysisMenu from "../components/OverlayMenuAnalysisMenu";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./AddUser2.module.css";
const AddUser2 = () => {
  const [isOverlayMenuAnalysisMenuOpen, setOverlayMenuAnalysisMenuOpen] =
    useState(false);
  const navigate = useNavigate();

  const openOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(true);
  }, []);

  const closeOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(false);
  }, []);

  const onButtonContainer3Click = useCallback(() => {
    navigate("/add-user1");
  }, [navigate]);

  const onButtonContainer4Click = useCallback(() => {
    navigate("/view-users-super");
  }, [navigate]);

  const onHomeIcon1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onProjectsClick = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onUsersClick = useCallback(() => {
    navigate("/view-users-super");
  }, [navigate]);

  return (
    <>
      <div className={styles.addUser2}>
        <div className={styles.content}>
          <div className={styles.stepper}>
            <div className={styles.step}>
              <div className={styles.button}>
                <img className={styles.checkIcon} alt="" src="/check.svg" />
              </div>
              <div className={styles.photo}>Photo</div>
            </div>
            <img className={styles.separatorIcon} alt="" src="/separator.svg" />
            <div className={styles.step}>
              <div className={styles.button}>
                <img className={styles.checkIcon} alt="" src="/check.svg" />
              </div>
              <div className={styles.photo}>Details</div>
            </div>
            <img
              className={styles.separatorIcon1}
              alt=""
              src="/separator1.svg"
            />
            <div className={styles.step2}>
              <div className={styles.button2}>
                <img className={styles.checkIcon} alt="" src="/shield.svg" />
              </div>
              <div className={styles.photo}>Permissions</div>
            </div>
          </div>
          <div className={styles.step21}>
            <div className={styles.frameParent}>
              <div className={styles.studyAppPermissionsParent}>
                <div className={styles.studyApp}>Study / App permissions</div>
                <div className={styles.studyNames}>Study names</div>
                <div className={styles.inputCheckboxParent}>
                  <div className={styles.inputCheckbox}>
                    <div className={styles.checkboxRadioButton} />
                    <div className={styles.option}>Efficiency Study</div>
                  </div>
                  <div className={styles.inputCheckbox}>
                    <div className={styles.checkboxRadioButton} />
                    <div className={styles.option}>Role Study</div>
                  </div>
                  <div className={styles.inputCheckbox}>
                    <div className={styles.checkboxRadioButton} />
                    <div className={styles.option}>Activity Study</div>
                  </div>
                </div>
                <div className={styles.inputCheckboxGroup}>
                  <div className={styles.inputCheckbox}>
                    <img
                      className={styles.checkboxRadioButton3}
                      alt=""
                      src="/checkbox--radio-button.svg"
                    />
                    <div className={styles.option}>Auto-generate</div>
                  </div>
                  <div className={styles.inputCheckbox}>
                    <div className={styles.checkboxRadioButton} />
                    <div className={styles.option}>Allow edit</div>
                  </div>
                </div>
              </div>
              <div className={styles.userPermissionsParent}>
                <div className={styles.userPermissions}>User permissions</div>
                <div className={styles.inputCheckboxContainer}>
                  <div className={styles.inputCheckbox}>
                    <img
                      className={styles.checkboxRadioButton5}
                      alt=""
                      src="/checkbox--radio-button1.svg"
                    />
                    <div className={styles.option}>Admin</div>
                  </div>
                  <div className={styles.inputCheckbox}>
                    <div className={styles.checkboxRadioButton6} />
                    <div className={styles.option}>View Only/Field User</div>
                  </div>
                  <div className={styles.inputCheckbox}>
                    <div className={styles.checkboxRadioButton6} />
                    <div className={styles.option}>Super User</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.stepperFooter}>
              <div className={styles.stepperFooter1}>
                <div className={styles.stepperFooterChild} />
                <div className={styles.buttonParent}>
                  <div
                    className={styles.button3}
                    onClick={onButtonContainer3Click}
                  >
                    <img
                      className={styles.plusCircleIcon}
                      alt=""
                      src="/pluscircle.svg"
                    />
                    <b className={styles.userPermissions}>Cancel</b>
                  </div>
                  <div className={styles.uploadingStatus}>
                    <img
                      className={styles.fileTextIcon}
                      alt=""
                      src="/filetext.svg"
                    />
                    <div className={styles.fileInfo}>
                      <div className={styles.photo}>Company_logo.jpg</div>
                      <div className={styles.progress}>
                        <div className={styles.progressBg} />
                        <div className={styles.progress1} />
                      </div>
                      <div className={styles.sizeAndProgress}>
                        <div className={styles.of57Mb}>4.2 of 5.7 mb</div>
                        <div className={styles.uploading72}>
                          Uploading... 72%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.button5}
                    onClick={onButtonContainer4Click}
                  >
                    <b className={styles.userPermissions}>Save and Add User</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.topBar}>
          <b className={styles.topBarText}>Add New User</b>
          <div className={styles.topBarChild} />
          <img className={styles.vectorIcon} alt="" src="/vector1.svg" />
          <div className={styles.search}>Search...</div>
          <img className={styles.topBarItem} alt="" src="/ellipse-20@2x.png" />
          <b className={styles.loggedUser}>Rushan Arshad</b>
          <img className={styles.vectorIcon1} alt="" src="/vector8.svg" />
        </div>
        <div className={styles.universalsidebar}>
          <div className={styles.universalsidebar1} />
          <img className={styles.homeIcon} alt="" />
          <img className={styles.logoIcon} alt="" src="/logo3.svg" />
          <img
            className={styles.homeIcon1}
            alt=""
            src="/home.svg"
            onClick={onHomeIcon1Click}
          />
          <img
            className={styles.projectsIcon}
            alt=""
            src="/projects.svg"
            onClick={onProjectsClick}
          />
          <img
            className={styles.usersIcon}
            alt=""
            src="/users.svg"
            onClick={onUsersClick}
          />
          <img
            className={styles.analysisIcon}
            alt=""
            src="/analysis.svg"
            onClick={openOverlayMenuAnalysisMenu}
          />
          <img className={styles.settingsIcon} alt="" src="/settings.svg" />
        </div>
      </div>
      {isOverlayMenuAnalysisMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeOverlayMenuAnalysisMenu}
        >
          <OverlayMenuAnalysisMenu onClose={closeOverlayMenuAnalysisMenu} />
        </PortalPopup>
      )}
    </>
  );
};

export default AddUser2;
