import { useState, useCallback } from "react";
import SectorMenu from "../components/SectorMenu";
import PortalPopup from "../components/PortalPopup";
import OverlayMenuAnalysisMenu from "../components/OverlayMenuAnalysisMenu";
import { useNavigate } from "react-router-dom";
import styles from "./CoreAnalysisEfficiencySecto3.module.css";
const CoreAnalysisEfficiencySecto3 = () => {
  const [isSectorMenuOpen, setSectorMenuOpen] = useState(false);
  const [isOverlayMenuAnalysisMenuOpen, setOverlayMenuAnalysisMenuOpen] =
    useState(false);
  const navigate = useNavigate();

  const openSectorMenu = useCallback(() => {
    setSectorMenuOpen(true);
  }, []);

  const closeSectorMenu = useCallback(() => {
    setSectorMenuOpen(false);
  }, []);

  const openOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(true);
  }, []);

  const closeOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(false);
  }, []);

  const onVectorIconClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onButtonContainerClick = useCallback(() => {
    navigate("/core-analysis-menu");
  }, [navigate]);

  const onButtonContainer1Click = useCallback(() => {
    navigate("/core-analysis-efficiency-sector");
  }, [navigate]);

  const onButtonGeneralVariant4ContainerClick = useCallback(() => {
    navigate("/benchmarking-efficiency");
  }, [navigate]);

  const onButtonGeneralVariant4Container1Click = useCallback(() => {
    navigate("/benchmarking-efficiency");
  }, [navigate]);

  const onButtonGeneralVariant4Container2Click = useCallback(() => {
    navigate("/benchmarking-efficiency");
  }, [navigate]);

  const onButtonGeneralVariant4Container3Click = useCallback(() => {
    navigate("/benchmarking-efficiency");
  }, [navigate]);

  const onButtonGeneralVariant4Container4Click = useCallback(() => {
    navigate("/benchmarking-efficiency");
  }, [navigate]);

  const onButtonGeneralVariant4Container5Click = useCallback(() => {
    navigate("/benchmarking-efficiency");
  }, [navigate]);

  const onButtonGeneralVariant4Container6Click = useCallback(() => {
    navigate("/benchmarking-efficiency");
  }, [navigate]);

  const onButtonGeneralVariant4Container7Click = useCallback(() => {
    navigate("/benchmarking-efficiency");
  }, [navigate]);

  const onButtonGeneralVariant4Container8Click = useCallback(() => {
    navigate("/benchmarking-efficiency");
  }, [navigate]);

  const onButtonGeneralVariant4Container9Click = useCallback(() => {
    navigate("/benchmarking-efficiency");
  }, [navigate]);

  const onButtonGeneralVariant4Container10Click = useCallback(() => {
    navigate("/benchmarking-efficiency");
  }, [navigate]);

  const onButtonGeneralVariant4Container11Click = useCallback(() => {
    navigate("/benchmarking-efficiency");
  }, [navigate]);

  const onButtonGeneralVariant4Container12Click = useCallback(() => {
    navigate("/benchmarking-efficiency");
  }, [navigate]);

  const onButtonGeneralVariant4Container13Click = useCallback(() => {
    navigate("/benchmarking-efficiency");
  }, [navigate]);

  const onButtonGeneralVariant4Container14Click = useCallback(() => {
    navigate("/benchmarking-efficiency");
  }, [navigate]);

  const onButtonGeneralVariant4Container15Click = useCallback(() => {
    navigate("/benchmarking-efficiency");
  }, [navigate]);

  const onHomeIcon1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onProjectsClick = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onUsersClick = useCallback(() => {
    navigate("/view-users-super");
  }, [navigate]);

  return (
    <>
      <div className={styles.coreAnalysisEfficiencySecto}>
        <img
          className={styles.vectorIcon}
          alt=""
          src="/vector5.svg"
          onClick={onVectorIconClick}
        />
        <div className={styles.topBarBenchmarking}>
          <b className={styles.coreAnalysisEfficiency}>
            Core Analysis Efficiency Study
          </b>
          <div className={styles.bxbxSearchParent}>
            <img
              className={styles.bxbxSearchIcon}
              alt=""
              src="/bxbxsearch.svg"
            />
            <div className={styles.search}>Search...</div>
          </div>
          <img
            className={styles.topBarBenchmarkingChild}
            alt=""
            src="/ellipse-20@2x.png"
          />
          <b className={styles.rushanArshad}>Rushan Arshad</b>
          <img
            className={styles.bxbxChevronDownIcon}
            alt=""
            src="/bxbxchevrondown.svg"
          />
        </div>
        <div className={styles.button} onClick={onButtonContainerClick}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus2.svg" />
          <b className={styles.button1}>Back</b>
        </div>
        <div className={styles.analysisContentArea}>
          <div className={styles.table1}>
            <div className={styles.table}>
              <div className={styles.row}>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <b className={styles.text}>Sector</b>
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <b className={styles.text}>Industry</b>
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <b className={styles.text}>Client_Name</b>
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <b className={styles.text}>Study</b>
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <b className={styles.text}>Date</b>
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
                <div className={styles.cell}>
                  <div className={styles.content}>
                    <div className={styles.text} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.dateRange}>Date Range</div>
          <div className={styles.analysisContentAreaChild} />
          <div className={styles.analysisContentAreaItem} />
          <div className={styles.to}>To</div>
          <div className={styles.sector}>Sector</div>
          <div className={styles.analysisContentAreaInner} />
          <img
            className={styles.groupIcon}
            alt=""
            src="/group-11.svg"
            onClick={openSectorMenu}
          />
          <div className={styles.button2} onClick={onButtonContainer1Click}>
            <img className={styles.userPlus2Icon} alt="" src="/userplus2.svg" />
            <b className={styles.button1}>Show Data</b>
          </div>
        </div>
        <div className={styles.menubenchmarkingstudies} />
        <div
          className={styles.buttongeneralvariant4}
          onClick={onButtonGeneralVariant4ContainerClick}
        >
          <img className={styles.vectorIcon1} alt="" src="/vector6.svg" />
          <b className={styles.efficiencyStudy}>Industry</b>
        </div>
        <div
          className={styles.buttongeneralvariant4}
          onClick={onButtonGeneralVariant4Container1Click}
        >
          <img className={styles.vectorIcon1} alt="" src="/vector7.svg" />
          <b className={styles.efficiencyStudy}>Industry</b>
        </div>
        <div
          className={styles.buttongeneralvariant42}
          onClick={onButtonGeneralVariant4Container2Click}
        >
          <img className={styles.vectorIcon1} alt="" src="/vector7.svg" />
          <b className={styles.efficiencyStudy}>Sector</b>
        </div>
        <div
          className={styles.buttongeneralvariant42}
          onClick={onButtonGeneralVariant4Container3Click}
        >
          <img className={styles.vectorIcon1} alt="" src="/vector6.svg" />
          <b className={styles.efficiencyStudy}>Sector</b>
        </div>
        <div
          className={styles.buttongeneralvariant44}
          onClick={onButtonGeneralVariant4Container4Click}
        >
          <img className={styles.vectorIcon1} alt="" src="/vector7.svg" />
          <b className={styles.efficiencyStudy}>Area</b>
        </div>
        <div
          className={styles.buttongeneralvariant44}
          onClick={onButtonGeneralVariant4Container5Click}
        >
          <img className={styles.vectorIcon1} alt="" src="/vector7.svg" />
          <b className={styles.efficiencyStudy}>Area</b>
        </div>
        <div
          className={styles.buttongeneralvariant46}
          onClick={onButtonGeneralVariant4Container6Click}
        >
          <img className={styles.vectorIcon1} alt="" src="/vector7.svg" />
          <b className={styles.efficiencyStudy}>Area</b>
        </div>
        <div
          className={styles.buttongeneralvariant46}
          onClick={onButtonGeneralVariant4Container7Click}
        >
          <img className={styles.vectorIcon1} alt="" src="/vector7.svg" />
          <b className={styles.efficiencyStudy}>Area</b>
        </div>
        <div
          className={styles.buttongeneralvariant48}
          onClick={onButtonGeneralVariant4Container8Click}
        >
          <img className={styles.vectorIcon1} alt="" src="/vector7.svg" />
          <b className={styles.efficiencyStudy}>Area</b>
        </div>
        <div
          className={styles.buttongeneralvariant48}
          onClick={onButtonGeneralVariant4Container9Click}
        >
          <img className={styles.vectorIcon1} alt="" src="/vector7.svg" />
          <b className={styles.efficiencyStudy}>Area</b>
        </div>
        <div
          className={styles.buttongeneralvariant410}
          onClick={onButtonGeneralVariant4Container10Click}
        >
          <img className={styles.vectorIcon1} alt="" src="/vector7.svg" />
          <b className={styles.efficiencyStudy}>Area</b>
        </div>
        <div
          className={styles.buttongeneralvariant410}
          onClick={onButtonGeneralVariant4Container11Click}
        >
          <img className={styles.vectorIcon1} alt="" src="/vector7.svg" />
          <b className={styles.efficiencyStudy}>Area</b>
        </div>
        <div
          className={styles.buttongeneralvariant412}
          onClick={onButtonGeneralVariant4Container12Click}
        >
          <img className={styles.vectorIcon1} alt="" src="/vector7.svg" />
          <b className={styles.efficiencyStudy}>Area</b>
        </div>
        <div
          className={styles.buttongeneralvariant412}
          onClick={onButtonGeneralVariant4Container13Click}
        >
          <img className={styles.vectorIcon1} alt="" src="/vector7.svg" />
          <b className={styles.efficiencyStudy}>Area</b>
        </div>
        <div
          className={styles.buttongeneralvariant414}
          onClick={onButtonGeneralVariant4Container14Click}
        >
          <img className={styles.vectorIcon1} alt="" src="/vector7.svg" />
          <b className={styles.efficiencyStudy}>Area</b>
        </div>
        <div
          className={styles.buttongeneralvariant414}
          onClick={onButtonGeneralVariant4Container15Click}
        >
          <img className={styles.vectorIcon1} alt="" src="/vector7.svg" />
          <b className={styles.efficiencyStudy}>Area</b>
        </div>
        <b className={styles.industry}>Industry</b>
        <b className={styles.industry}>Industry</b>
        <b className={styles.sector1}>Sector</b>
        <b className={styles.sector1}>Sector</b>
        <b className={styles.area}>Area</b>
        <b className={styles.area}>Area</b>
        <b className={styles.category}>Category</b>
        <b className={styles.category}>Category</b>
        <b className={styles.element}>Element</b>
        <b className={styles.element}>Element</b>
        <b className={styles.task}>Task</b>
        <b className={styles.task}>Task</b>
        <b className={styles.timeOfDay}>Time of Day</b>
        <b className={styles.timeOfDay}>Time of Day</b>
        <b className={styles.customer}>Customer</b>
        <b className={styles.customer}>Customer</b>
        <div className={styles.universalsidebar}>
          <div className={styles.universalsidebar1} />
          <img className={styles.homeIcon} alt="" />
          <img className={styles.logoIcon} alt="" src="/logo.svg" />
          <img
            className={styles.homeIcon1}
            alt=""
            src="/home.svg"
            onClick={onHomeIcon1Click}
          />
          <img
            className={styles.projectsIcon}
            alt=""
            src="/projects.svg"
            onClick={onProjectsClick}
          />
          <img
            className={styles.usersIcon}
            alt=""
            src="/users.svg"
            onClick={onUsersClick}
          />
          <img
            className={styles.analysisIcon}
            alt=""
            src="/analysis1.svg"
            onClick={openOverlayMenuAnalysisMenu}
          />
          <img className={styles.settingsIcon} alt="" src="/settings.svg" />
        </div>
      </div>
      {isSectorMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSectorMenu}
        >
          <SectorMenu onClose={closeSectorMenu} />
        </PortalPopup>
      )}
      {isOverlayMenuAnalysisMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeOverlayMenuAnalysisMenu}
        >
          <OverlayMenuAnalysisMenu onClose={closeOverlayMenuAnalysisMenu} />
        </PortalPopup>
      )}
    </>
  );
};

export default CoreAnalysisEfficiencySecto3;
