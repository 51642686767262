import { useState, useCallback } from "react";
import OverlayMenuAnalysisMenu from "../components/OverlayMenuAnalysisMenu";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./CoreAnalysisEfficiencySecto1.module.css";
const CoreAnalysisEfficiencySecto1 = () => {
  const [isOverlayMenuAnalysisMenuOpen, setOverlayMenuAnalysisMenuOpen] =
    useState(false);
  const navigate = useNavigate();

  const openOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(true);
  }, []);

  const closeOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(false);
  }, []);

  const onButtonContainerClick = useCallback(() => {
    navigate("/core-analysis-menu");
  }, [navigate]);

  const onGroupIconClick = useCallback(() => {
    navigate("/sector-menu");
  }, [navigate]);

  const onButtonContainer19Click = useCallback(() => {
    navigate("/core-analysis-efficiency-sector2");
  }, [navigate]);

  const onHomeIcon1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onProjectsClick = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onUsersClick = useCallback(() => {
    navigate("/view-users-super");
  }, [navigate]);

  return (
    <>
      <div className={styles.coreAnalysisEfficiencySecto}>
        <b className={styles.coreAnalysisEfficiency}>
          Core Analysis Efficiency Study
        </b>
        <img className={styles.vectorIcon} alt="" src="/vector1.svg" />
        <div className={styles.search}>Search...</div>
        <img
          className={styles.coreAnalysisEfficiencySectoChild}
          alt=""
          src="/ellipse-20@2x.png"
        />
        <b className={styles.rushanArshad}>Rushan Arshad</b>
        <img
          className={styles.bxbxChevronDownIcon}
          alt=""
          src="/bxbxchevrondown.svg"
        />
        <div className={styles.button} onClick={onButtonContainerClick}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus2.svg" />
          <b className={styles.button1}>Back</b>
        </div>
        <div className={styles.efficiencyCoreTab}>
          <div className={styles.efficiencyCoreTabChild} />
        </div>
        <div className={styles.efficiencyCoreTab}>
          <div className={styles.efficiencyCoreTabChild} />
        </div>
        <div className={styles.button2}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus21.svg" />
          <b className={styles.button1}>{`Industry `}</b>
        </div>
        <div className={styles.button2}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus21.svg" />
          <b className={styles.button1}>{`Industry `}</b>
        </div>
        <div className={styles.button6}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus22.svg" />
          <b className={styles.button1}>Customer</b>
        </div>
        <div className={styles.button6}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus22.svg" />
          <b className={styles.button1}>Customer</b>
        </div>
        <div className={styles.button10}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus22.svg" />
          <b className={styles.button1}>Time of Day</b>
        </div>
        <div className={styles.button10}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus22.svg" />
          <b className={styles.button1}>Time of Day</b>
        </div>
        <div className={styles.button14}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus21.svg" />
          <b className={styles.button1}>Time</b>
        </div>
        <div className={styles.button14}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus21.svg" />
          <b className={styles.button1}>Time</b>
        </div>
        <div className={styles.button18}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus22.svg" />
          <b className={styles.button1}>Task</b>
        </div>
        <div className={styles.button18}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus22.svg" />
          <b className={styles.button1}>Task</b>
        </div>
        <div className={styles.button22}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus22.svg" />
          <b className={styles.button1}>Element</b>
        </div>
        <div className={styles.button22}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus22.svg" />
          <b className={styles.button1}>Element</b>
        </div>
        <div className={styles.button26}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus21.svg" />
          <b className={styles.button1}>Category</b>
        </div>
        <div className={styles.button26}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus21.svg" />
          <b className={styles.button1}>Category</b>
        </div>
        <div className={styles.button30}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus21.svg" />
          <b className={styles.button1}>Area</b>
        </div>
        <div className={styles.button30}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus21.svg" />
          <b className={styles.button1}>Area</b>
        </div>
        <div className={styles.button34}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus21.svg" />
          <b className={styles.button1}>Sector</b>
        </div>
        <div className={styles.button34}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus21.svg" />
          <b className={styles.button1}>Sector</b>
        </div>
        <div className={styles.table1}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <b className={styles.text}>Sector</b>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <b className={styles.text}>Industry</b>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <b className={styles.text}>Client_Name</b>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <b className={styles.text}>Study</b>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <b className={styles.text}>Date</b>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>Telecommunications</div>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>Retail</div>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>Vodafone</div>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>Dobp2701ras1</div>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>2023-01-27</div>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>Clinic</div>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>Vet</div>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>Medivet</div>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>Wegw0602ras1</div>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>2023-02-06</div>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>Sector</div>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>Industry</div>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>Client_Name</div>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>Study</div>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>Date</div>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>Sector</div>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>Industry</div>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>Client_Name</div>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>Study</div>
                </div>
              </div>
              <div className={styles.cell}>
                <div className={styles.content}>
                  <div className={styles.text}>Date</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dateRange}>Date Range</div>
        <div className={styles.coreAnalysisEfficiencySectoItem} />
        <div className={styles.div}>2023-01-01</div>
        <div className={styles.coreAnalysisEfficiencySectoInner} />
        <div className={styles.div1}>2023-0-31</div>
        <div className={styles.to}>To</div>
        <div className={styles.sector}>Sector</div>
        <div className={styles.rectangleDiv} />
        <div className={styles.telecommunications}>Telecommunications</div>
        <img
          className={styles.groupIcon}
          alt=""
          src="/group-11.svg"
          onClick={onGroupIconClick}
        />
        <div className={styles.button38} onClick={onButtonContainer19Click}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus2.svg" />
          <b className={styles.button1}>Show Data</b>
        </div>
        <div className={styles.universalsidebar}>
          <div className={styles.universalsidebar1} />
          <img className={styles.homeIcon} alt="" />
          <img className={styles.logoIcon} alt="" src="/logo.svg" />
          <img
            className={styles.homeIcon1}
            alt=""
            src="/home.svg"
            onClick={onHomeIcon1Click}
          />
          <img
            className={styles.projectsIcon}
            alt=""
            src="/projects.svg"
            onClick={onProjectsClick}
          />
          <img
            className={styles.usersIcon}
            alt=""
            src="/users.svg"
            onClick={onUsersClick}
          />
          <img
            className={styles.analysisIcon}
            alt=""
            src="/analysis1.svg"
            onClick={openOverlayMenuAnalysisMenu}
          />
          <img className={styles.settingsIcon} alt="" src="/settings.svg" />
        </div>
      </div>
      {isOverlayMenuAnalysisMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeOverlayMenuAnalysisMenu}
        >
          <OverlayMenuAnalysisMenu onClose={closeOverlayMenuAnalysisMenu} />
        </PortalPopup>
      )}
    </>
  );
};

export default CoreAnalysisEfficiencySecto1;
