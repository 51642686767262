import { useState, useCallback } from "react";
import OverlayMenuAnalysisMenu from "../components/OverlayMenuAnalysisMenu";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./CoreAnalysisMenu.module.css";
const CoreAnalysisMenu = () => {
  const [isOverlayMenuAnalysisMenuOpen, setOverlayMenuAnalysisMenuOpen] =
    useState(false);
  const navigate = useNavigate();

  const openOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(true);
  }, []);

  const closeOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(false);
  }, []);

  const onButtonContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onButtonGeneralVariant4ContainerClick = useCallback(() => {
    navigate("/core-analysis-efficiency");
  }, [navigate]);

  const onButtonGeneralVariant5ContainerClick = useCallback(() => {
    navigate("/benchmarking-activity");
  }, [navigate]);

  const onButtonGeneralVariant6ContainerClick = useCallback(() => {
    navigate("/benchmarking-role");
  }, [navigate]);

  const onHomeIcon1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onProjectsClick = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onUsersClick = useCallback(() => {
    navigate("/view-users-super");
  }, [navigate]);

  return (
    <>
      <div className={styles.coreAnalysisMenu}>
        <b className={styles.coreAnalysis}>Core Analysis</b>
        <img className={styles.vectorIcon} alt="" src="/vector1.svg" />
        <div className={styles.search}>Search...</div>
        <img
          className={styles.coreAnalysisMenuChild}
          alt=""
          src="/ellipse-20@2x.png"
        />
        <b className={styles.rushanArshad}>Rushan Arshad</b>
        <img className={styles.vectorIcon1} alt="" src="/vector8.svg" />
        <div className={styles.button} onClick={onButtonContainerClick}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus2.svg" />
          <b className={styles.button1}>Back</b>
        </div>
        <div className={styles.menubenchmarkingstudies} />
        <div
          className={styles.buttongeneralvariant4}
          onClick={onButtonGeneralVariant4ContainerClick}
        >
          <img className={styles.vectorIcon2} alt="" src="/vector2.svg" />
          <b className={styles.efficiencyStudy}>Efficiency Study</b>
        </div>
        <div
          className={styles.buttongeneralvariant5}
          onClick={onButtonGeneralVariant5ContainerClick}
        >
          <img className={styles.vectorIcon2} alt="" src="/vector3.svg" />
          <b className={styles.activityStudy}>Activity Study</b>
        </div>
        <div
          className={styles.buttongeneralvariant6}
          onClick={onButtonGeneralVariant6ContainerClick}
        >
          <img className={styles.vectorIcon2} alt="" src="/vector4.svg" />
          <b className={styles.activityStudy}>Role Study</b>
        </div>
        <div className={styles.universalsidebar}>
          <div className={styles.universalsidebar1} />
          
          <img className={styles.logoIcon} alt="" src="/logo.svg" />
          <img
            className={styles.homeIcon1}
            alt=""
            src="/home.svg"
            onClick={onHomeIcon1Click}
          />
          <img
            className={styles.projectsIcon}
            alt=""
            src="/projects.svg"
            onClick={onProjectsClick}
          />
          <img
            className={styles.usersIcon}
            alt=""
            src="/users.svg"
            onClick={onUsersClick}
          />
          <img
            className={styles.analysisIcon}
            alt=""
            src="/analysis1.svg"
            onClick={openOverlayMenuAnalysisMenu}
          />
          <img className={styles.settingsIcon} alt="" src="/settings.svg" />
        </div>
      </div>
      {isOverlayMenuAnalysisMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeOverlayMenuAnalysisMenu}
        >
          <OverlayMenuAnalysisMenu onClose={closeOverlayMenuAnalysisMenu} />
        </PortalPopup>
      )}
    </>
  );
};

export default CoreAnalysisMenu;
