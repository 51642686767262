import {
  Routes,
  Route,
  useNavigationType,
  
  useLocation,
} from "react-router-dom";
import { useState } from "react";
import SuperUserMenu from "./pages/SuperUserMenu";
import BenchmarkingAnalysis from "./pages/BenchmarkingAnalysis";
import LoginWindow from "./pages/LoginWindow";
import CoreAnalysisEfficiencySecto from "./pages/CoreAnalysisEfficiencySecto";
import CoreAnalysisEfficiencySecto1 from "./pages/CoreAnalysisEfficiencySecto1";
import BenchmarkingRole from "./pages/BenchmarkingRole";
import CoreAnalysisEfficiencySecto2 from "./pages/CoreAnalysisEfficiencySecto2";
import CoreAnalysisEfficiencySecto3 from "./pages/CoreAnalysisEfficiencySecto3";
import CoreAnalysisEfficiency from "./pages/CoreAnalysisEfficiency";
import CoreAnalysisMenu from "./pages/CoreAnalysisMenu";
import BenchmarkingEfficiency from "./pages/BenchmarkingEfficiency";
import BenchmarkingActivity from "./pages/BenchmarkingActivity";
import ShowBenchmarkingCharts from "./pages/ShowBenchmarkingCharts";
import AddUser2 from "./pages/AddUser2";
import AddUser1 from "./pages/AddUser1";
import AddUser from "./pages/AddUser";
import Projects from "./pages/Projects";
import ViewUsersSuper from "./pages/ViewUsersSuper";
import BxbxChevronDown from "./pages/BxbxChevronDown";
import ReThinkProductivityLogo1 from "./pages/ReThinkProductivityLogo1";
import Login from "./pages/Login";
import Simulation from "./pages/Simulation";
import BenchmarkingEfficiencyExternal from "./pages/EfficiencyStudyExternal";
import BenchmarkingRoleExternal from "./pages/ExternalRoleBenchmarking";
import UpdateUser from "./pages/UpdateUser";
import { useEffect } from "react";
import UserContext from './pages/UserContext';

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/benchmarking-analysis":
        title = "";
        metaDescription = "";
        break;
      case "/loginwindow":
        title = "";
        metaDescription = "";
        break;
      case "/core-analysis-efficiency-sector3":
        title = "";
        metaDescription = "";
        break;
      case "/core-analysis-efficiency-sector2":
        title = "";
        metaDescription = "";
        break;
      case "/benchmarking-role":
        title = "";
        metaDescription = "";
        break;
      case "/core-analysis-efficiency-sector1":
        title = "";
        metaDescription = "";
        break;
      case "/core-analysis-efficiency-sector":
        title = "";
        metaDescription = "";
        break;
      case "/core-analysis-efficiency":
        title = "";
        metaDescription = "";
        break;
      case "/core-analysis-menu":
        title = "";
        metaDescription = "";
        break;
      case "/benchmarking-efficiency":
        title = "";
        metaDescription = "";
        break;
      case "/benchmarking-activity":
        title = "";
        metaDescription = "";
        break;
      case "/show-benchmarking-charts":
        title = "";
        metaDescription = "";
        break;
      case "/add-user2":
        title = "";
        metaDescription = "";
        break;
      case "/add-user1":
        title = "";
        metaDescription = "";
        break;
      case "/add-user":
        title = "";
        metaDescription = "";
        break;
      case "/UpdateUser":
          title = "";
          metaDescription = "";
          break;
      case "/projects":
        title = "";
        metaDescription = "";
        break;
      case "/view-users-super":
        title = "";
        metaDescription = "";
        break;
      case "/bxbxchevrondown":
        title = "";
        metaDescription = "";
        break;
      case "/rethinkproductivitylogo-1":
        title = "";
        metaDescription = "";
        break;
      case "/login":
        title = "";
        metaDescription = "";
        break;
      case "/Simulation":
          title = "";
          metaDescription = "";
          break;
      case "/benchmarking-efficiency-external":
            title = "";
            metaDescription = "";
            break;
      case "/benchmarking-role-external":
              title = "";
              metaDescription = "";
              break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
  
  <UserContext.Provider value={{ user, setUser }}>
    <Routes>
      <Route path="/" element={<LoginWindow />} />
      <Route path="/superusermenu" element={<SuperUserMenu />} />
      <Route path="/tttid" element={<BenchmarkingAnalysis />} />
      <Route path="/loginwindow" element={<LoginWindow />} />
      <Route
        path="/core-analysis-efficiency-sector3"
        element={<CoreAnalysisEfficiencySecto />}
      />
      <Route
        path="/core-analysis-efficiency-sector2"
        element={<CoreAnalysisEfficiencySecto1 />}
      />
      <Route path="/vvvid" element={<BenchmarkingRole />} />
      <Route
        path="/core-analysis-efficiency-sector1"
        element={<CoreAnalysisEfficiencySecto2 />}
      />
      <Route
        path="/core-analysis-efficiency-sector"
        element={<CoreAnalysisEfficiencySecto3 />}
      />
      <Route
        path="/core-analysis-efficiency"
        element={<CoreAnalysisEfficiency />}
      />
      <Route path="/core-analysis-menu" element={<CoreAnalysisMenu />} />
      <Route
        path="/uuuid"
        element={<BenchmarkingEfficiency />}
      />
      <Route
        path="/uuuiid"
        element={<BenchmarkingEfficiencyExternal />}
      />
      <Route
        path="/vvviid"
        element={<BenchmarkingRoleExternal />}
      />
      <Route path="/wwwid" element={<BenchmarkingActivity />} />
      <Route
        path="/show-benchmarking-charts"
        element={<ShowBenchmarkingCharts />}
      />
      <Route path="/add-user2" element={<AddUser2 />} />
      <Route path="/add-user1" element={<AddUser1 />} />
      <Route path="/add-user" element={<AddUser />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/UpdateUser" element={<UpdateUser />} />
      <Route path="/view-users-super" element={<ViewUsersSuper />} />
      <Route path="/bxbxchevrondown" element={<BxbxChevronDown />} />
      <Route
        path="/rethinkproductivitylogo-1"
        element={<ReThinkProductivityLogo1 />}
      />
      <Route path="/login" element={<Login />} />
      
    </Routes>
    </UserContext.Provider>
  

  );

}
export default App;
