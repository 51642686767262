import styles from "./ReThinkProductivityLogo1.module.css";
const ReThinkProductivityLogo1 = () => {
  return (
    <div className={styles.rethinkProductivityLogo1}>
      <img className={styles.surface1Icon} alt="" src="/surface1.svg" />
    </div>
  );
};

export default ReThinkProductivityLogo1;
