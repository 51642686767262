import { useState, useCallback } from "react";
import OverlayMenuAnalysisMenu from "../components/OverlayMenuAnalysisMenu";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./Projects.module.css";
const Projects = () => {
  const [isOverlayMenuAnalysisMenuOpen, setOverlayMenuAnalysisMenuOpen] =
    useState(false);
  const navigate = useNavigate();

  const openOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(true);
  }, []);

  const closeOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(false);
  }, []);

  const onButtonContainer1Click = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onHomeIcon1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onProjectsClick = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onUsersClick = useCallback(() => {
    navigate("/view-users-super");
  }, [navigate]);

  return (
    <>
      <div className={styles.projects}>
        <div className={styles.setSomeFiltersToGetReportParent}>
          <div className={styles.setSomeFilters}>
            Set some filters to get reports
          </div>
          <img className={styles.filterIcon} alt="" src="/filter.svg" />
          <img className={styles.frameChild} alt="" src="/vector-52.svg" />
        </div>
        <div className={styles.pageTitle}>
          <b className={styles.label}>Projects</b>
          <div className={styles.button}>
            <img
              className={styles.userPlus2Icon}
              alt=""
              src="/userplus23.svg"
            />
            <b className={styles.button1}>Add customer</b>
          </div>
        </div>
        <div className={styles.filters}>
          <div className={styles.inputlabelParent}>
            <div className={styles.inputlabel}>
              <div className={styles.label}>Customer</div>
              <div className={styles.dropdown}>
                <div className={styles.label}>Choose customer</div>
                <img
                  className={styles.userPlus2Icon}
                  alt=""
                  src="/chevrondown1.svg"
                />
              </div>
              <div className={styles.errorField}>Error field</div>
            </div>
            <div className={styles.inputlabel}>
              <div className={styles.label}>Project</div>
              <div className={styles.dropdown1}>
                <div className={styles.label}>Choose project</div>
                <img
                  className={styles.userPlus2Icon}
                  alt=""
                  src="/chevrondown2.svg"
                />
              </div>
              <div className={styles.errorField}>Error field</div>
            </div>
            <div className={styles.inputlabel2}>
              <div className={styles.label}>Locations</div>
              <div className={styles.dropdown1}>
                <div className={styles.label}>Select locations</div>
                <img
                  className={styles.userPlus2Icon}
                  alt=""
                  src="/chevrondown3.svg"
                />
              </div>
              <div className={styles.errorField}>Error field</div>
            </div>
            <div className={styles.dateRange}>
              <div className={styles.studyDateRange}>Study date range</div>
              <div className={styles.buttonGroup}>
                <div className={styles.from}>
                  <div className={styles.fromParent}>
                    <b className={styles.from1}>From</b>
                    <div className={styles.bigBan}>Big Ban</div>
                  </div>
                  <img
                    className={styles.userPlus2Icon}
                    alt=""
                    src="/calendar1.svg"
                  />
                </div>
                <div className={styles.to}>
                  <div className={styles.toParent}>
                    <b className={styles.from1}>to</b>
                    <div className={styles.now}>Now</div>
                  </div>
                  <img
                    className={styles.userPlus2Icon}
                    alt=""
                    src="/calendar1.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <div className={styles.button2} onClick={onButtonContainer1Click}>
              <div className={styles.link}>Clear filters</div>
            </div>
          </div>
        </div>
        <div className={styles.universalsidebar}>
          <div className={styles.universalsidebar1} />
          
          <img className={styles.logoIcon} alt="" src="/logo4.svg" />
          <img
            className={styles.homeIcon1}
            alt=""
            src="/home.svg"
            onClick={onHomeIcon1Click}
          />
          <img
            className={styles.projectsIcon}
            alt=""
            src="/projects.svg"
            onClick={onProjectsClick}
          />
          <img
            className={styles.usersIcon}
            alt=""
            src="/users.svg"
            onClick={onUsersClick}
          />
          <img
            className={styles.analysisIcon}
            alt=""
            src="/analysis.svg"
            onClick={openOverlayMenuAnalysisMenu}
          />
          <img className={styles.settingsIcon} alt="" src="/settings.svg" />
        </div>
        <div className={styles.topBar}>
          <b className={styles.topBarText}>ReThink Projects</b>
          <div className={styles.topBarChild} />
          <img className={styles.vectorIcon} alt="" src="/vector1.svg" />
          <div className={styles.search}>Search...</div>
          <img className={styles.topBarItem} alt="" src="/ellipse-20@2x.png" />
          <b className={styles.loggedUser}>Rushan Arshad</b>
          <img className={styles.vectorIcon1} alt="" src="/vector10.svg" />
        </div>
      </div>
      {isOverlayMenuAnalysisMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeOverlayMenuAnalysisMenu}
        >
          <OverlayMenuAnalysisMenu onClose={closeOverlayMenuAnalysisMenu} />
        </PortalPopup>
      )}
    </>
  );
};

export default Projects;
