import { useState, useCallback, useEffect } from "react";
import OverlayMenuAnalysisMenu from "../components/OverlayMenuAnalysisMenu";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./ViewUsersSuper.module.css";
import axios from 'axios';
import { Oval } from 'react-loader-spinner';

const useFetch = (url) => { // Move the definition of useFetch outside of the component
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (url) {
      fetch(url)
        .then((response) => response.json())
        .then((json) => {
          console.log("Fetched Data:", json); 
          setData(json);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [url]);

  return [data, loading];
};





const ViewUsersSuper = () => {
  const [isOverlayMenuAnalysisMenuOpen, setOverlayMenuAnalysisMenuOpen] =
    useState(false);
  const navigate = useNavigate();

  const openOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(true);
  }, []);

  const closeOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(false);
  }, []);

  const onButtonClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHomeIcon1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onProjectsClick = useCallback(() => {
    navigate("/projects");
  }, [navigate]);

  const onUsersClick = useCallback(() => {
    navigate("/view-users-super");
  }, [navigate]);

  const onButtonGeneralVariant4Click = useCallback(() => {
    navigate("/add-user1");
  }, [navigate]);

  const [users, setUsers] = useState([false]);

  
  //const [showUsers, setShowUsers] = useState(false);


  const [usersData, setUsersData] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [showUsers, setShowUsers] = useState(false);

  const onShowUsersClick = () => {
    setShowUsers(true);
    setUsersLoading(true);
    fetch('http://localhost:5000/api/getUsers')
      .then((response) => response.json())
      .then((json) => {
        console.log("Fetched Data:", json); 
        setUsersData(json);
        setUsersLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setUsersLoading(false);
      });
  };


  
  
  


  return (
    <>
      <div className={styles.viewUsersSuper}>
            {showUsers && usersLoading ? (
              <div className={styles.loadingContainer}>
                <Oval color="#a2c73b" height={80} width={80} />
              </div>
            ) : (
              showUsers && (
                <div className={styles.usersTable}>
                  <table>
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Password</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Type</th>
                        <th>Subscription End</th>
                        <th>Company Name</th>
                        <th>Industry</th>
                      </tr>
                    </thead>
                    <tbody>
                      {usersData.map((user, index) => (
                        <tr key={index}>
                          <td>{user.username}</td>
                          <td>{user.password}</td> {/* Be cautious displaying passwords */}
                          <td>{user.first_name}</td>
                          <td>{user.last_name}</td>
                          <td>{user.email}</td>
                          <td>{user.type}</td>
                          <td>{user.sub_end}</td>
                          <td>{user.company_name}</td>
                          <td>{user.industry}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            )}




        
        <button className={styles.button} onClick={onButtonClick}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus24.svg" />
          <b className={styles.button1}>Back</b>
        </button>
        <button className={styles.button} onClick={onShowUsersClick}>
          <img className={styles.userPlus2Icon} alt="" src="/showusers24.svg" />
          <b className={styles.button1}>Show Users</b>
        </button>

        
        <div className={styles.universalsidebar}>
          <div className={styles.universalsidebar1} />
          
          <img className={styles.logoIcon} alt="" src="/logo3.svg" />
          <img
            className={styles.homeIcon1}
            alt=""
            src="/home.svg"
            onClick={onHomeIcon1Click}
          />
          <img
            className={styles.projectsIcon}
            alt=""
            src="/projects.svg"
            onClick={onProjectsClick}
          />
          <img
            className={styles.usersIcon}
            alt=""
            src="/users.svg"
            onClick={onUsersClick}
          />
          <img
            className={styles.analysisIcon}
            alt=""
            src="/analysis.svg"
            onClick={openOverlayMenuAnalysisMenu}
          />
          <img className={styles.settingsIcon} alt="" src="/settings.svg" />
        </div>
        <div className={styles.topBar}>
          <b className={styles.topBarText}>List of ReThink Users</b>
          <div className={styles.topBarChild} />
          <img className={styles.vectorIcon} alt="" src="/vector1.svg" />
          <div className={styles.search}>Search...</div>
          <img className={styles.topBarItem} alt="" src="/ellipse-20@2x.png" />
          <b className={styles.loggedUser}>Rushan Arshad</b>
          <img className={styles.vectorIcon1} alt="" src="/vector8.svg" />
        </div>
        <button
          className={styles.buttongeneralvariant4}
          onClick={onButtonGeneralVariant4Click}
        >
          <img className={styles.vectorIcon2} alt="" src="/vector9.svg" />
          <b className={styles.addUser}>Add User</b>
          <img className={styles.adduserIcon} alt="" src="/adduser.svg" />
        </button>
      </div>
      {isOverlayMenuAnalysisMenuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeOverlayMenuAnalysisMenu}
        >
          <OverlayMenuAnalysisMenu onClose={closeOverlayMenuAnalysisMenu} />
        </PortalPopup>
      )}
    </>
  );
};

export default ViewUsersSuper;
