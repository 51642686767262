import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AddUser.module.css";
const AddUser = () => {
  const navigate = useNavigate();

  const onButtonContainer6Click = useCallback(() => {
    navigate("/add-user1");
  }, [navigate]);

  return (
    <div className={styles.addUser}>
      <div className={styles.addUser1}>Add User</div>
      <img className={styles.groupIcon} alt="" src="/group.svg" />
      <div className={styles.sidebar}>
        <div className={styles.sidebarBg} />
        <div className={styles.version185}>
          <p className={styles.version}>Version</p>
          <p className={styles.version}>1.8.5</p>
        </div>
        <div className={styles.menu}>
          <div className={styles.reports}>
            <img
              className={styles.clipboard2TonesIcon}
              alt=""
              src="/clipboard2tones.svg"
            />
          </div>
          <div className={styles.customers}>
            <img
              className={styles.clipboard2TonesIcon}
              alt=""
              src="/truck2tones.svg"
            />
          </div>
          <div className={styles.projects}>
            <img
              className={styles.clipboard2TonesIcon}
              alt=""
              src="/layers2tones.svg"
            />
          </div>
          <div className={styles.users}>
            <img
              className={styles.clipboard2TonesIcon}
              alt=""
              src="/users2tones.svg"
            />
          </div>
          <div className={styles.projects}>
            <img
              className={styles.clipboard2TonesIcon}
              alt=""
              src="/compass2tones.svg"
            />
          </div>
          <div className={styles.projects}>
            <img
              className={styles.clipboard2TonesIcon}
              alt=""
              src="/piechart2tones.svg"
            />
          </div>
          <div className={styles.projects}>
            <img
              className={styles.clipboard2TonesIcon}
              alt=""
              src="/settings2tones.svg"
            />
          </div>
        </div>
        <img className={styles.retimeLogoIcon} alt="" src="/retime-logo.svg" />
        <img className={styles.collapseIcon} alt="" src="/collapse.svg" />
        <img
          className={styles.topLineSeparator}
          alt=""
          src="/top-line-separator.svg"
        />
      </div>
      <div className={styles.content}>
        <div className={styles.stepper}>
          <div className={styles.step}>
            <div className={styles.button}>
              <img
                className={styles.clipboard2TonesIcon}
                alt=""
                src="/user.svg"
              />
            </div>
            <div className={styles.photo}>Photo</div>
          </div>
          <img className={styles.separatorIcon} alt="" src="/separator3.svg" />
          <div className={styles.step1}>
            <div className={styles.button1}>
              <img
                className={styles.clipboard2TonesIcon}
                alt=""
                src="/information.svg"
              />
            </div>
            <div className={styles.photo}>Details</div>
          </div>
          <img className={styles.separatorIcon1} alt="" src="/separator2.svg" />
          <div className={styles.step1}>
            <div className={styles.button1}>
              <img
                className={styles.clipboard2TonesIcon}
                alt=""
                src="/shield1.svg"
              />
            </div>
            <div className={styles.photo}>Permissions</div>
          </div>
        </div>
        <div className={styles.step11}>
          <div className={styles.dropYourPhoto}>
            <div className={styles.buttonParent}>
              <div className={styles.button3}>
                <div className={styles.link}>Remove</div>
              </div>
              <div className={styles.dropAPhotoHereOrParent}>
                <div className={styles.dropAPhoto}>Drop a photo here or</div>
                <div className={styles.button4}>
                  <div className={styles.link}>Browse your files</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.stepperFooter}>
            <div className={styles.stepperFooterChild} />
            <div className={styles.buttonGroup}>
              <div className={styles.button5}>
                <img
                  className={styles.plusCircleIcon}
                  alt=""
                  src="/pluscircle.svg"
                />
                <b className={styles.button6}>Cancel</b>
              </div>
              <div className={styles.uploadingStatus}>
                <img
                  className={styles.fileTextIcon}
                  alt=""
                  src="/filetext2.svg"
                />
                <div className={styles.fileInfo}>
                  <div className={styles.photo}>Company_logo.jpg</div>
                  <div className={styles.progress}>
                    <div className={styles.progressBg} />
                    <div className={styles.progress1} />
                  </div>
                  <div className={styles.sizeAndProgress}>
                    <div className={styles.of57Mb}>4.2 of 5.7 mb</div>
                    <div className={styles.uploading72}>Uploading... 72%</div>
                  </div>
                </div>
              </div>
              <div className={styles.button7} onClick={onButtonContainer6Click}>
                <b className={styles.button6}>Save and Continue</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
