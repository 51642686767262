import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./OverlayMenuAnalysisMenu.module.css";
const OverlayMenuAnalysisMenu = ({ onClose }) => {
  const navigate = useNavigate();

  const onDropdownMenuOption1Click = useCallback(() => {
    navigate("/tttid");
  }, [navigate]);

  const onDropdownMenuOption3Click = useCallback(() => {
    navigate("/core-analysis-menu");
  }, [navigate]);
  const onDropdownMenuOption2Click = useCallback(() => {
    navigate("/simulation");
  }, [navigate]);

  return (
    <div className={styles.overlaymenuanalysismenu}>
      <img className={styles.clipPathGroup} alt="" src="/clip-path-group.svg" />
      <div className={styles.dropdownMenuContents}>
        <div className={styles.dropdownMenuOption}>
          <div className={styles.byMonth}>All Industries</div>
        </div>
        <div
          className={styles.dropdownMenuOption1}
          onClick={onDropdownMenuOption1Click}
        >
          <div className={styles.byMonth}>Benchmarking</div>
        </div>
        <div className={styles.dropdownMenuOption1}
          onClick={onDropdownMenuOption2Click}
        >
          <div className={styles.byMonth}>Simulation</div>
        </div>
        <div
          className={styles.dropdownMenuOption1}
          onClick={onDropdownMenuOption3Click}
        >
          <div className={styles.byMonth}>Core Analysis</div>
        </div>
        <div className={styles.dropdownMenuOption2}>
          <div className={styles.byMonth}>Data Exploration</div>
        </div>
        <div className={styles.dropdownMenuOption2}>
          <div className={styles.byMonth} />
        </div>
        
      </div>
    </div>
  );
};

export default OverlayMenuAnalysisMenu;
