import { useRef, useState, useCallback, useEffect } from "react";
import OverlayMenuAnalysisMenu from "../components/OverlayMenuAnalysisMenu";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./BenchmarkingRole.module.css";
import Select from 'react-select';
import config from "./config";
import moment from 'moment';
import { Oval } from 'react-loader-spinner';
import Plot from 'react-plotly.js';
import UserContext from './UserContext';
const useFetch = (url) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchUrl = async () => {
    try {
      const response = await fetch(url, { credentials: 'include' });
      const rawData = await response.json();
      
      const formattedData = rawData.map(item  => {
        // Split the string at the hyphen and capitalize the first letter of the second part
        const parts = item.split('-');
        if (parts.length > 1) {
          parts[1] = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
        }
        
        const formattedItem = parts.join(' ');
        
        return { value: item, label: formattedItem };
      });
  
      // Sorting the data alphabetically
      const sortedData = formattedData.sort((a, b) => a.label.localeCompare(b.label));
  
      setData(sortedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (url) {
      fetchUrl();
    }
  }, [url]);

  return [data, loading];
};

//Functions to fetch and plot charts

const fetchChartData = async (url, clients, excludedClient, projects, role,c_projects,c_role, start, end, position,) => {
  try {
    console.log('Payload Before Sending: ',JSON.stringify({ clients, excluded_client: excludedClient, projects, role, c_projects, c_role, start_date: start, end_date: end, position }));

    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      
      body: JSON.stringify({ clients, excluded_client: excludedClient, projects, role, c_projects, c_role, start_date: start, end_date: end, position }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const fetchManagementData = async (url, clients, excluded_client,projects, role, c_projects,c_roles, start, end) => {
  try {
    // Send roles array in the request body
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({clients, excluded_client, projects, role, c_projects, c_roles,start_date: start, end_date: end }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching management data:", error);
  }
};

const clientColorMap = {
  "Customer": '#004225',
  "Task & Process": '#a7c5f9',
  "NVA": '#800517',
  "Efficiency Index": '#878787',
  "Pace Rating": '#02557a',
  // Add other chart types and colors here
};


const plotChart = (data, title, color, selectedClient, selectedOption, xKey = '%') => {
  // Map the client data
  const clientData = data.map((record) => {
    let xValue = record[xKey];
    let textValue = record[xKey] / 100;
    const isEmpty = xValue === 0.0;
  
    
  
    return {
      x: [xValue],
      y: [record.benchmark_org],
      type: 'bar',
      orientation: 'h',
      width: 0.75,
      text: [textValue],
      texttemplate: '%{text:.0%}',
      name: record.client_name,
      marker: { color: [record.benchmark_org === selectedClient ? clientColorMap[selectedOption] : color] },
      hoverinfo: isEmpty ? 'none' : 'all',  // Disable hover info for empty bars
      showlegend: !isEmpty,  // Hide legend for empty bars
      editable: true,
    };
  });

  const clientAnnotations = clientData
  .filter((data) => data.x[0] === 0.0)
  .map((data, index) => ({
    x: 10,
    y: data.y[0],
    text: '0.0%',
    showarrow: false,
    font: {
      color: 'black',
      size: 14,
    },
  }));

  
  // Add the industry average data as a separate trace
  const industryAvgData = {
    x: [data[0]['industry_avg']],
    y: [[' Industry Average']],
    type: 'bar',
    orientation: 'h',
    width: 0.75,
    text: [data[0]['industry_avg'] / 100],
    texttemplate: '%{text:.0%}',
    name: 'Industry Average',
    marker: { color: '#7650c3' }, // Color for industry average
  };
  const sectorAvgData = {
    x: [data[0]['sector_avg']], // Make sure the server sends this key
    y: [[' Sector Average']],
    type: 'bar',
    orientation: 'h',
    width: 0.75,
    text: [data[0]['sector_avg'] / 100],
    texttemplate: '%{text:.0%}',
    name: 'Sector Average',
    marker: { color: '#7650c3' }, // Choose a color for the sector average
  };

  // Generate annotation for industryAvgData if value is 0.0%
  const industryAvgAnnotation = industryAvgData.x[0] === 0.0 ? [{
    x: 10,
    y: ' Industry Average',
    text: '0.0%',
    showarrow: false,
    font: {
      color: 'black',
      size: 14,
    },
  }] : [];

  // Generate annotation for sectorAvgData if value is 0.0%
  const sectorAvgAnnotation = sectorAvgData.x[0] === 0.0 ? [{
    x: 10,
    y: ' Sector Average',
    text: '0.0%',
    showarrow: false,
    font: {
      color: 'black',
      size: 14,
    },
  }] : [];

  // Combine all annotations
  const annotations = [...clientAnnotations, ...industryAvgAnnotation, ...sectorAvgAnnotation];

  

  return (
    <Plot
      data={[...clientData, industryAvgData,sectorAvgData]}
      layout={{
        //backgroundcolor: "rgb(255,0,0)",
        width:550,
        height: 'auto',
        xaxis: { title },
        template: 'plotly_white',
        barmode: 'group',
        showlegend: false,
        margin: {
          l: 250,
          r: 50,
          b: 150,
          t: 100,
          pad: 10
        },
        annotations: annotations, // Add the annotations to the layout
      }}
    />
  );
};





const plotManagementChart = (data, title, c_roles) => {
  console.log('Data:', data);
  const roles = Object.keys(data); // Get roles as an array
  console.log('Roles:', roles);

  const peopleManagementTrace = {
    x: roles,
    y: roles.map(role => Math.round(parseFloat(data[role].people_management_percentage))),
    text: roles.map(role => `${Math.round(parseFloat(data[role].people_management_percentage))}%`),
    textposition: 'inside',
    textfont: {
      color: 'white',
    },
    name: 'People Management',
    type: 'bar',
    marker: {
      color: '#7650C3',
    },
  };

  const managementTrace = {
    x: roles,
    y: roles.map(role => Math.round(parseFloat(data[role].management_percentage))),
    text: roles.map(role => `${Math.round(parseFloat(data[role].management_percentage))}%`),
    textposition: 'inside',
    textfont: {
      color: 'white',
    },
    name: 'General Management',
    type: 'bar',
    marker: {
      color: '#0bbbef',
    },
  };

  return (
    <Plot
      data={[peopleManagementTrace, managementTrace]} 
      layout={{
        title: {
          text: 'The data on this chart consists of tasks by Management related<br>to People Management and General Management',
          font: {
              size: 18
                // Adjust the font size accordingly
          }
      },
        barmode: 'stack',
        xaxis: {
          title: 'Roles',
          tickvals: roles,
          ticktext: roles.map(role => 
            `<span style='color:${c_roles.includes(role) ? 'black' : '#0bbbef'}'>${role}</span>`
        ),
        
        },
        
        yaxis: {
          title: 'Percentage (%) of Time',
        },
      }}
    />
  );
};

const fetchTotalManagementData = async (url, clients, excluded_client, projects, role, c_projects, c_roles, start, end) => {
  try {
    // Send roles array in the request body
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ clients, excluded_client, projects, role, c_projects, c_roles, start_date: start, end_date: end }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching total management data:", error);
  }
};
const plotTotalManagementChart = (data, title) => {
  console.log('Data:', data);
  
  // Separate data for roles and c_roles
  const roles = Object.keys(data.roles || {});
  const c_roles = Object.keys(data.c_roles || {});

  const totalManagementTrace = {
      x: roles,
      y: roles.map(role => Math.round(parseFloat(data.roles[role].total_management))),
      text: roles.map(role => `${Math.round(parseFloat(data.roles[role].total_management))}%`),
      textposition: 'inside',
     
      textfont: {
          color: 'white',
      },
      name: 'Client Roles',
      type: 'bar',
      marker: {
          color: '#F7A12C',
      },
  };

  const cRolesTotalManagementTrace = {
      x: c_roles,
      y: c_roles.map(role => Math.round(parseFloat(data.c_roles[role].total_management))),
      text: c_roles.map(role => `${Math.round(parseFloat(data.c_roles[role].total_management))}%`),
      textposition: 'inside',
      title: 'The data for this chart consists of tasks by Management related to Management, People Management, Talk Work, Management - General, Management - People',
      textfont: {
          color: 'white',
      },
      name: 'Comparator Roles',
      type: 'bar',
      marker: {
          color: '#0bbbef',  // Color for c_roles
      },
  };

  return (
    <Plot
        data={[totalManagementTrace, cRolesTotalManagementTrace]}
        layout={{
            title: {
                text: 'The data on this chart consists of tasks by Management related<br>to Management, People Management, Talk Work, <br>Management - General, Management - People',
                font: {
                    size: 18
                      // Adjust the font size accordingly
                }
            },
            barmode: 'stack',
            xaxis: {
                title: 'Roles',
            },
            yaxis: {
                title: 'Percentage (%) of Time',
            },
        }}
    />
);
      };


const BenchmarkingRoleExternal = () => {
  const [isOverlayMenuAnalysisMenuOpen, setOverlayMenuAnalysisMenuOpen] =
    useState(false);
  const navigate = useNavigate();

  //const [lastActivity, setLastActivity] = useState(Date.now());

  //const handleActivity = () => {
    //setLastActivity(Date.now());
  //};

  //useEffect(() => {
    //const timeout = setTimeout(() => {
      //if (Date.now() - lastActivity > 60000) { // 1 minute
        // Logout the user
        //navigate('/');
      //}
    //}, 600000); // Check every minute

    // Listen for any user activity
    //window.addEventListener('mousemove', handleActivity);
    //window.addEventListener('keydown', handleActivity);

    // Cleanup
    //return () => {
      //clearTimeout(timeout);
      //window.removeEventListener('mousemove', handleActivity);
      //window.removeEventListener('keydown', handleActivity);
    //};
//  }, [lastActivity, navigate]);

  

  const closeOverlayMenuAnalysisMenu = useCallback(() => {
    setOverlayMenuAnalysisMenuOpen(false);
  }, []);
  
  const [project, setProject] = useState('');
 

  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedCompetitors, setSelectedCompetitors] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  

  const [competitorProjects, setCompetitorProjects] = useState([]);
  const [selectedCompetitorProject, setSelectedCompetitorProject] = useState([]);
  const [competitorRoles, setCompetitorRoles] = useState([]);
  const [selectedCompetitorRoles, setSelectedCompetitorRoles] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [selectedOption, setSelectedOption] = useState("");

  const [errorMessage, setErrorMessage] = useState(null);
  const [showError, setShowError] = useState(false);


  
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // loading state for chart generation

  const [selectedChartType, setSelectedChartType] = useState('');
  const isPeopleAndGenMgt = selectedChartType === 'People and Gen Mgt';
  const isTotMgt = selectedChartType === 'Total Management';

// Handler for chart type change
  const handleChartTypeChange = (selectedOption) => {
    setSelectedChartType(selectedOption.value);
  };

  // Chart Type Options
  const chartTypeOptions = [
    { value: 'Benchmarking', label: 'Benchmarking' },
    { value: 'People and Gen Mgt', label: 'People and Gen Mgt' },
    { value: 'Total Management', label: 'Total Management' },
  ];

  


  useEffect(() => {
    if (selectedCompetitors && selectedCompetitors.length > 0) {
        fetch(`${config.server_url}/getRoleCompetitorProjectsExternal`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ clients: selectedCompetitors })
        })
        .then(response => response.json())
        .then(data => {
            // Convert data into the format expected by the Select component
            const formattedData = [];
            for (const [competitor, projects] of Object.entries(data)) {
                formattedData.push({
                    label: competitor,
                    options: projects.map(project => ({ value: project, label: project }))
                });
            }
            setCompetitorProjects(formattedData);
        })
        .catch(error => console.error("Error fetching competitor projects:", error));
    }
}, [selectedCompetitors]);


//competitors Roles
useEffect(() => {
  if (selectedCompetitors && selectedCompetitors.length > 0) {
    fetch(`${config.server_url}/getCompetitorRolesExternal/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ clients: selectedCompetitors })
    })
    .then(response => response.json())
    .then(data => {
      const formattedData = [];
      for (const [competitor, roles] of Object.entries(data)) {
        formattedData.push({
          label: competitor,
          options: roles.map(role => ({ value: role, label: role }))
        });
      }
      setCompetitorRoles(formattedData);  // Make sure this state exists
    })
    .catch(error => console.error("Error fetching competitor roles:", error));
  }
}, [selectedCompetitors]);


  const [taskAndProcessData, setTaskAndProcessData] = useState([]);
  const [nvaData, setNvaData] = useState([]);
  const [efficiencyIndexData, setEfficiencyIndexData] = useState([]);
  const [paceData, setpaceData] = useState([]);
  const [mngtData, setmngtData] = useState([]);
  const [totmngtData, settotmngtData] = useState([]);



  

  const onButtonContainer1Click = useCallback(() => {
    navigate("/vvviid");
  }, [navigate]);

  const onButtonGeneralVariant4ContainerClick = useCallback(() => {
    navigate("/uuuiid");
  }, [navigate]);

  const onButtonGeneralVariant5ContainerClick = useCallback(() => {
    navigate("/vvviid");
  }, [navigate]);

  const onButtonGeneralVariant6ContainerClick = useCallback(() => {
    navigate("/vvviid");
  }, [navigate]);

  const onHomeIcon1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onProjectsClick = useCallback(() => {
    navigate("/vvviid");
  }, [navigate]);

  const onUsersClick = useCallback(() => {
    navigate("/vvviid");
  }, [navigate]);


  const handleCompetitorsChange = (selectedOptions) => {
    setSelectedCompetitors(selectedOptions.map(option => option.value));
  };
  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption.value);
  };
  const handleRoleChange = (selectedOptions) => {
    setSelectedRole(selectedOptions.map(option => option.value));
  };
  const handlePositionChange = (selectedOption) => {
    setSelectedPosition(selectedOption.value);
  };

  const handleCompetitorProjectsChange = (selectedOptions) => {
    setSelectedCompetitorProject(selectedOptions.map(option => option.value));
  };
  const handleCompetitorRolesChange = (selectedOptions) => {
    setSelectedCompetitorRoles(selectedOptions.map(option => option.value));
  };
  const handleProjectsChange = (selectedOption) => {
    setSelectedProjects(selectedOption.value);
  };
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const encodedPosition = encodeURIComponent(selectedPosition);
  const encodedClientName = encodeURIComponent(selectedClient);

  const encodedprojectname = encodeURIComponent(selectedProjects);


  const [clients, clientsLoading] = useFetch(`${config.server_url}/getRoleClientsExternal`);
  const [competitors, competitorsLoading] = useFetch(`${config.server_url}/getRoleCompetitorsExternal/${encodedPosition}`);
  const [role, roleLoading] = useFetch(selectedClient ? `${config.server_url}/getRoleExternal/${encodedprojectname}` : null);
  const [position, positionLoading] = useFetch(`${config.server_url}/getPosition/` );
  const [projects, projectsLoading] = useFetch(selectedClient ? `${config.server_url}/getRoleProjectsExternal/${encodedClientName}` : null);


    //Clear All contents Button Handler
    const handleClearClick = () => {
      // Clear all dropdown selections
      setSelectedClient(null);
      setSelectedCompetitors(null);
      setSelectedProjects(null);
      setStartDate("");
      setEndDate("");
      setChartData([]);
      setmngtData([]);
      setSelectedRole([]);
      setSelectedCompetitorRoles([]);
      setSelectedCompetitorProject([]);
      setSelectedChartType("");
      setSelectedPosition([]);

      
      
      // Clear selected option
      setSelectedOption("");
    };
  
  const [overlayPosition, setOverlayPosition] = useState(null);
  const analysisIconRef = useRef(null);
     
   
  const openOverlayMenuAnalysisMenu = useCallback(() => {
       const rect = analysisIconRef.current.getBoundingClientRect();
       setOverlayPosition({
         top: rect.top + window.scrollY + rect.height,
         left: rect.left + window.scrollX,
       });
       setOverlayMenuAnalysisMenuOpen(true);
     }, []);
    

  const [chartData, setChartData] = useState([]);
  const urlMap = {
    "Customer": `${config.server_url}/processRoleCustomerExternal`,
    "Task & Process": `${config.server_url}/processRoletaskDataExternal`,
    "NVA": `${config.server_url}/processRoleNvaDataExternal`,
    "Efficiency Index": `${config.server_url}/processRoleEfficiencyIndexDataExternal`,
    "Pace Rating": `${config.server_url}/processRolePaceDataExternal`,
  };

  const colorMap = {
    "Customer": '#008649',
    "Task & Process": '#293771',
    "NVA": '#d60e41',
    "Efficiency Index": '#d0d0d0',
    "Pace Rating": '#0bbbef',
  };

  const handleGenerateClick = async () => {
    setErrorMessage(null);
    setShowError(false);
  
    if (!startDate || !endDate) {
      setErrorMessage("Please select date range");
      setShowError(true);
      return;
    }
  
    const start = startDate ? moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null;
    const end = endDate ? moment(endDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null;
    const clients = [...selectedCompetitors, selectedClient];
    const projects = selectedProjects;
    const c_projects = selectedCompetitorProject;
    const role = selectedRole;
    const c_roles = selectedCompetitorRoles;
    const position = selectedPosition;
  
    setIsLoading(true);
  
    if (selectedChartType === 'People and Gen Mgt') {
      const url = `${config.server_url}/management-index`;
      const data = await fetchManagementData(url, clients, selectedClient,projects, role, c_projects,c_roles, start, end);
      setmngtData(data);
      if (data && data.error) {
        setErrorMessage(data.error);
        setShowError(true);
        setIsLoading(false);
        return;
      }
    }else if (selectedChartType === 'Total Management') { // New block for "Total Management"
      const url = `${config.server_url}/management-total`;
      const data = await fetchTotalManagementData(url, clients, selectedClient, projects, role, c_projects, c_roles, start, end);
      settotmngtData(data);
      if (data && data.error) {
        setErrorMessage(data.error);
        setShowError(true);
        setIsLoading(false);
        return;
      } 
    }
    
    else {

      if (!selectedCompetitors || selectedCompetitors.length === 0) {
        setErrorMessage("Please select competitors");
        setShowError(true);
        return;
      }


      if (selectedOption === "All Charts") {
        // Fetch data for all charts
        for (const [option, url] of Object.entries(urlMap)) {
          const data = await fetchChartData(url, clients, selectedClient, projects, role, c_projects, c_roles, start, end, position);
          if (data && data.error) {
            setErrorMessage(data.error);
            setShowError(true);
            setIsLoading(false);
            return;
          }
    
          if (option === "Efficiency Index") {
            setEfficiencyIndexData(data);
          } else if (option === "Pace Rating") {
            setpaceData(data);
          } else if (option === "Customer") {
            setChartData(data);
          } else if (option === "Task & Process") {
            setTaskAndProcessData(data);
          } else if (option === "NVA") {
            setNvaData(data);
          }
        }
      } else {
        // Existing logic for individual charts
        const url = urlMap[selectedOption];
        const color = colorMap[selectedOption];
        const projects = selectedProjects;
        const c_projects = selectedCompetitorProject;
       
        const industry_avg = chartData['industry_avg'];
        const sector_avg = chartData['sector_avg'];
        const role = selectedRole;
        const c_role = selectedCompetitorRoles;
        const position = selectedPosition;
        console.log('Role value:',role);
    
    
        if (url) {
          console.log('Selected Role before fetch:', role); 
  
          const data = await fetchChartData(url, clients, selectedClient, projects, role, c_projects, c_role, start, end, position, industry_avg, sector_avg);
  
          if (data && data.error) {
            setErrorMessage(data.error);
            setShowError(true);
            setIsLoading(false);
            return;
          }
          
          if (selectedOption === "Efficiency Index") {
            setEfficiencyIndexData(data);
          } else if (selectedOption === "Pace Rating") {
            setpaceData(data);
          } else if (selectedOption === "Customer") {
            setChartData(data);
          }
          else if (selectedOption === "Task & Process") {
            setTaskAndProcessData(data);
          } else if (selectedOption === "NVA") {
            setNvaData(data);
          }
        }
      }
    
      
    }
  
    
    setIsLoading(false);
  };
  
    

  return (
    <>
      <div className={styles.benchmarkingRole}>
        <div className={styles.dropdownsContainer}>
          
            <div className={styles.dropdown}>          
              <Select 
              value={clients.find(option => option.value === selectedClient) || ''}
              options={clients}
              //className={styles.dropdown}
              placeholder="SELECT CLIENT"
              onChange={handleClientChange}
              />
            </div>
            <div className={styles.dropdown}>
            
            <Select 
            value={(projects.find(option => option.value === selectedProjects) || '')}
            options={projects}
            
            
            placeholder="SELECT CLIENT PROJECT"
            onChange={handleProjectsChange}
        />
      </div>
      <div className={styles.dropdown}>
              
              <Select 
                value={(selectedRole || []).map(item => role.find(option => option.value === item))}
                options={role}
                //className={styles.dropdown}
                isMulti
                placeholder="SELECT CLIENT ROLE"
                onChange={handleRoleChange}
                />
            </div>

            <div className={styles.dropdown}>
              
              <Select 
                value={(position.find(option => option.value === selectedPosition) || '')}
                options={position}
                //className={styles.dropdown}
                
                placeholder="SELECT BENCHMARK POSITION"
                onChange={handlePositionChange}
                />
            </div>
           <div className="competitorContainer">
            <Select 
                value={(selectedCompetitors || []).map(item => competitors.find(option => option.value === item))}
                options={competitors}
                className={styles.dropdownCompetitor}
                isMulti
                placeholder="SELECT BENCHMARKING ORGANIZATION"
                onChange={handleCompetitorsChange}
                //isDisabled={isPeopleAndGenMgt}
              />
           </div>

       

         

          <div className ={styles.dropdown}>
                <Select 
                value={selectedCompetitorProject.map(item => {
                  for (const group of competitorProjects) {
                    const option = group.options.find(option => option.value === item);
                    if (option) {
                      return option;
                    }
                  }
                  return null;
                })}
                options={competitorProjects}
                
                isMulti
                placeholder="SELECT BENCHMARK PROJECTS"
                onChange={handleCompetitorProjectsChange}
                //isDisabled={isPeopleAndGenMgt}
                
            />



          </div>
          <div className ={styles.dropdown}>
                <Select 
                value={selectedCompetitorRoles.map(item => {
                  for (const group of competitorRoles) {
                    const option = group.options.find(option => option.value === item);
                    if (option) {
                      return option;
                    }
                  }
                  return null;
                })}
                options={competitorRoles}
                
                isMulti
                placeholder="SELECT BENCHMARK ROLES"
                onChange={handleCompetitorRolesChange}
                //isDisabled={isPeopleAndGenMgt}
                
            />



          </div>
        
        <input
          type="date"
          className={styles.dateInputContainer}
          value={startDate}
          placeholder="Start Date (YYYY-MM-DD)"
          onChange={e => setStartDate(e.target.value)}
        />
        <input
          type="date"
          className={styles.dateInputContainer}
          value={endDate}
          placeholder="End Date (YYYY-MM-DD)"
          onChange={e => setEndDate(e.target.value)}
        />

        



       <button type="button" onClick={handleClearClick} className={styles.clearButton}>
                  Clear
         
        </button>
        </div>
        <div className={styles.checkboxContainer}>
      <label>
        <input type="radio" value="Customer" checked={selectedOption === "Customer"} onChange={handleOptionChange} disabled={isPeopleAndGenMgt || isTotMgt} />
        Customer
      </label>
      <label>
        <input type="radio" value="Task & Process" checked={selectedOption === "Task & Process"} onChange={handleOptionChange} disabled={isPeopleAndGenMgt|| isTotMgt}/>
        Task & Process
      </label>
      <label>
        <input type="radio" value="NVA" checked={selectedOption === "NVA"} onChange={handleOptionChange} disabled={isPeopleAndGenMgt|| isTotMgt}/>
        NVA
      </label>
      <label>
        <input type="radio" value="Efficiency Index" checked={selectedOption === "Efficiency Index"} onChange={handleOptionChange}disabled={isPeopleAndGenMgt|| isTotMgt} />
        Efficiency Index
      </label>
      <label>
        <input type="radio" value="Pace Rating" checked={selectedOption === "Pace Rating"} onChange={handleOptionChange} disabled={isPeopleAndGenMgt|| isTotMgt}/>
        Pace Rating
      </label>
      <label>
        <input type="radio" value="All Charts" checked={selectedOption === "All Charts"} onChange={handleOptionChange} disabled={isPeopleAndGenMgt|| isTotMgt}/>
        All Charts
      </label>


    </div>
    {showError && (
        <PortalPopup
          placement="Centered"
          onOutsideClick={() => setShowError(false)}
        >
          <div className={styles.errorMessageBox}>
            <p className={styles.errorMessage}>{errorMessage}</p>
            <button className={styles.closeErrorButton} onClick={() => setShowError(false)}>Close</button>
          </div>
        </PortalPopup>
      )}


    {isLoading && (
        <div className={styles.loadingContainer}>
          <Oval color="#a2c73b" height={80} width={80} />
        </div>
      )}
    <div className={styles.chartContainer}>
        {selectedOption === "All Charts" && (
          <>
            {chartData.length > 0 && 
              plotChart(chartData, `Percentage of Customer - Role: ${selectedRole}`, colorMap["Customer"], selectedClient, "Customer")}
            {taskAndProcessData.length > 0 && 
              plotChart(taskAndProcessData, `Percentage of Task & Process - Role: ${selectedRole}`, colorMap["Task & Process"], selectedClient, "Task & Process")}
            {nvaData.length > 0 && 
              plotChart(nvaData, `Percentage of NVA - Role: ${selectedRole}`, colorMap["NVA"], selectedClient, "NVA")}
            {efficiencyIndexData.length > 0 && 
              plotChart(efficiencyIndexData, `Percentage of Efficiency Index - Role: ${selectedRole}`, colorMap["Efficiency Index"], selectedClient, "Efficiency Index", 'efficiency_index')}
            {paceData.length > 0 && 
              plotChart(paceData, `Percentage of Pace Rating - Role: ${selectedRole}`, colorMap["Pace Rating"], selectedClient, "Pace Rating", 'avg_pace')}
            
          </>
        )}
        {selectedOption === "Efficiency Index" && efficiencyIndexData.length > 0 && 
          plotChart(efficiencyIndexData, `Percentage of ${selectedOption} `, colorMap[selectedOption], selectedClient, selectedOption, 'efficiency_index')}
        {selectedOption === "Pace Rating" && paceData.length > 0 && 
          plotChart(paceData, `Percentage of ${selectedOption}`, colorMap[selectedOption], selectedClient, selectedOption, 'avg_pace')}
        {selectedOption === "Customer" && chartData.length > 0 && 
          plotChart(chartData, `Percentage of ${selectedOption}-  Role: ${selectedRole}`, colorMap[selectedOption], selectedClient, selectedOption)}
        {selectedOption === "Task & Process" && taskAndProcessData.length > 0 && 
          plotChart(taskAndProcessData, `Percentage of ${selectedOption} -  Role: ${selectedRole}`, colorMap[selectedOption], selectedClient, selectedOption)}
        {selectedOption === "NVA" && nvaData.length > 0 && 
          plotChart(nvaData, `Percentage of ${selectedOption}-  Role: ${selectedRole}`, colorMap[selectedOption], selectedClient, selectedOption)}
        
        
        {mngtData.length > 0 && plotManagementChart(mngtData, selectedRole, `People and General Management - Role: ${selectedRole}`)}
        {totmngtData.length > 0 && plotTotalManagementChart(totmngtData, selectedRole, `Total Management - Role: ${selectedRole}`)}

      </div>
        <div className={styles.topBarBenchmarking}>
          <b
            className={styles.roleStudyBenchmarking}
          >{`Role Study Benchmarking `}</b>
          <div className={styles.bxbxSearchParent}>
            <img
              className={styles.bxbxSearchIcon}
              alt=""
              src="/bxbxsearch.svg"
            />
            <div className={styles.search}>Search...</div>
          </div>
          
          <b className={styles.rushanArshad}><UserContext.Consumer>
              {({ user }) => user && <b className={styles.loggedUser}>{user.username}</b>}
            </UserContext.Consumer></b>
          <img
            className={styles.bxbxChevronDownIcon}
            alt=""
            src="/bxbxchevrondown.svg"
          />
        </div>
        
        <div className={styles.button}>
          <button type="button" onClick={handleGenerateClick}>
            <img className={styles.userPlus2Icon} alt="" src="/vector2.svg" />
            <b className={styles.button1}>Generate</b>
            </button>
        </div>
        <div className={styles.button2} onClick={onButtonContainer1Click}>
          <img className={styles.userPlus2Icon} alt="" src="/userplus2.svg" />
          <b className={styles.button1}>Back</b>
        </div>
        <div className={styles.menubenchmarkingstudies} />
        <div
          className={styles.buttongeneralvariant4}
          onClick={onButtonGeneralVariant4ContainerClick}
        >
          <img className={styles.vectorIcon} alt="" src="/vector2.svg" />
          <b className={styles.efficiencyStudy}>Efficiency Study</b>
        </div>
        <div
          className={styles.buttongeneralvariant5}
          onClick={onButtonGeneralVariant5ContainerClick}
        >
          <img className={styles.vectorIcon} alt="" src="/vector3.svg" />
          <b className={styles.activityStudy}>Activity Study</b>
        </div>
        <div
          className={styles.buttongeneralvariant6}
          onClick={onButtonGeneralVariant6ContainerClick}
        >
          <img className={styles.vectorIcon} alt="" src="/vector4.svg" />
          <b className={styles.activityStudy}>Role Study</b>
        </div>
        <div className={styles.universalsidebar}>
          <div className={styles.universalsidebar1} />
          
          <img className={styles.logoIcon} alt="" src="/logo.svg" />
          <img
            className={styles.homeIcon1}
            alt=""
            src="/home.svg"
            onClick={onHomeIcon1Click}
          />
          <img
            className={styles.projectsIcon}
            alt=""
            src="/projects.svg"
            onClick={onProjectsClick}
          />
          <img
            className={styles.usersIcon}
            alt=""
            src="/users.svg"
            onClick={onUsersClick}
          />
          <img
            className={styles.analysisIcon}
            ref={analysisIconRef}
            alt=""
            src="/analysis.svg"
            onClick={openOverlayMenuAnalysisMenu}
          />
          <img className={styles.settingsIcon} alt="" src="/settings.svg" />
        </div>
      </div>
      
    </>
  );
};

export default BenchmarkingRoleExternal;
