import { useState, useCallback, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./LoginWindow.module.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserContext from './UserContext'; // Adjust this import according to your file structure

const LoginWindow = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [lastActivity, setLastActivity] = useState(Date.now());
  const { setUser } = useContext(UserContext);

  const onButtonGeneralVariant4Click = useCallback(async () => {
    try {
      const response = await fetch('https://rebenchmark.co.uk/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: username,
          password: password
        }),
        credentials: 'include' // Include this line to ensure credentials are included with the request
      });
  
      const data = await response.json();
  
      if (response.status === 200) {
        // Login was successful
        toast.success(data.message); // shows success message in a toast
        setUser({ username: data.username });
  
        // Navigate based on user_type
        if (data.user_type === 'user' || data.user_type === 'User') {
          navigate("/uuuiid");
        } else {
          navigate("/superusermenu");
        }
      } else {
        // Login failed
        toast.error(data.error); // shows error message in a toast
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred'); // shows error message in a toast
    }
  }, [username, password, navigate]);
  

  const handleActivity = () => {
    setLastActivity(Date.now());
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (Date.now() - lastActivity > 60000) { // 1 minute
        // Logout the user
        navigate('/');
      }
    }, 600000); // Check every minute

    // Listen for any user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    // Cleanup
    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [lastActivity, navigate]);

  return (
    <div className={styles.loginwindow}>
      <img className={styles.logoIcon} alt="" src="/logo2.svg" />
      
      
      <input
        type="text"
        className={styles.generaltextfieldIcon}
        value={username}
        onChange={e => setUsername(e.target.value)}
        placeholder="Username"
      />
      
      <input
        type="password"
        className={styles.generaltextfieldIcon1}
        value={password}
        onChange={e => setPassword(e.target.value)}
        placeholder="Password"
      />
      <button
        className={styles.buttongeneralvariant4}
        onClick={onButtonGeneralVariant4Click}
      >
        <img className={styles.vectorIcon} alt="" src="/vector.svg" />
        <b className={styles.login}>Login</b>
      </button>
      <div className={styles.forgotPassword}>Forgot Password?</div>
      <ToastContainer />
    </div>
  );
};

export default LoginWindow;
